<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <!-- v-show="printShow" -->
    <div id="printDiv" v-show="printShow">
      <template
        v-if="
          params.productType == 'CSFMU-S' ||
          params.productType == 'CSFMU-B' ||
          params.productType == 'SDNP'
        "
      >
        <CSFMU
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></CSFMU>
      </template>
      <template v-if="params.productType == 'F&V'">
        <FANDV
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></FANDV>
      </template>
      <template v-if="params.productType == 'FMU-D'">
        <FMUD
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></FMUD>
      </template>
      <template v-if="params.productType == 'HEXT'">
        <HEXT
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></HEXT>
      </template>
      <template v-if="params.productType == 'PCG'">
        <PCG
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></PCG>
      </template>
      <template v-if="params.productType == 'REXT'">
        <REXT
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></REXT>
      </template>
      <template v-if="params.productType == 'OEXT'">
        <OEXT
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></OEXT>
      </template>
      <template
        v-if="
          params.productType == 'PCG-CSFMU-S' ||
          params.productType == 'PCG-CSFMU-B'
        "
      >
        <PCGCSFM
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></PCGCSFM>
      </template>
      <template v-if="params.productType == 'SHXTO'">
        <SHXTO
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></SHXTO>
      </template>
      <template v-if="params.productType == 'EHXTO'">
        <EHXTO
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></EHXTO>
      </template>
      <template
        v-if="
          params.productType == 'Liquid EXT-S' ||
          params.productType == 'Liquid EXT-B' ||
          params.productType == 'SDNP-L'
        "
      >
        <LiquidEXT
          :printModel="this.$data"
          style="height-max: 1000px; width: 100vw; padding: 0 40px 0 40px"
        ></LiquidEXT>
      </template>
    </div>
    <div>
      <div class="new-item-head">
        TECH DOC. EDITOR Import {{ titleType }}
        <span v-if="showVerified">
          <i :class="verify_show.icon" :style="{ color: verify_show.color }"></i>
        </span>
        <span
          style="position: absolute; left: 10px; cursor: pointer"
          @click="back"
        >
          <i class="el-icon-arrow-left"></i>
        </span>
        <span
          style="
            position: absolute;
            right: 10px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
          "
        >
          <el-dropdown placement v-if="userInfo.username">
            <div>
              <i class="el-icon-user" style="margin-right: 10px"></i>
              {{ userInfo.username }}
              <i class="el-icon-arrow-down"></i>
              <!-- <span class="user-name">语言切换</span> -->
              <el-dropdown-menu class="user-dropdown" slot="dropdown">
                <el-dropdown-item @click.native="logoutHandle"
                  >Logout</el-dropdown-item
                >
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </span>
      </div>
      
    <div v-if="isShowAlonePrint" style="width: 21cm; margin-left: 25%">
      <div id="alonePrintDiv">
        <template
          v-if="
            params.productType == 'CSFMU-S' ||
            params.productType == 'CSFMU-B' ||
            params.productType == 'SDNP'
          "
        >
          <CSFMU
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></CSFMU>
        </template>
        <template v-if="params.productType == 'F&V'">
          <FANDV
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></FANDV>
        </template>
        <template v-if="params.productType == 'FMU-D'">
          <FMUD
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></FMUD>
        </template>
        <template v-if="params.productType == 'HEXT'">
          <HEXT
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></HEXT>
        </template>
        <template v-if="params.productType == 'PCG'">
          <PCG
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></PCG>
        </template>
        <template v-if="params.productType == 'REXT'">
          <REXT
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></REXT>
        </template>
        <template v-if="params.productType == 'OEXT'">
          <OEXT
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></OEXT>
        </template>
        <template
          v-if="
            params.productType == 'PCG-CSFMU-S' ||
            params.productType == 'PCG-CSFMU-B'
          "
        >
          <PCGCSFM
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></PCGCSFM>
        </template>
        <template v-if="params.productType == 'SHXTO'">
          <SHXTO
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></SHXTO>
        </template>
        <template v-if="params.productType == 'EHXTO'">
          <EHXTO
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></EHXTO>
        </template>
        <template
          v-if="
            params.productType == 'Liquid EXT-S' ||
            params.productType == 'Liquid EXT-B' ||
            params.productType == 'SDNP-L'
          "
        >
          <LiquidEXT
            :printModel="this.$data"
            style="height-max: 1000px;"
          ></LiquidEXT>
        </template>
      </div>
      <div style="margin-top: 20px;margin-bottom:100px">
        <el-button type="info" icon="el-icon-edit" @click="showUpdate()" v-if="isShowUpdate">Update</el-button>
        <el-button type="success" icon="el-icon-printer" @click="aloneprintPage()">Print</el-button>
        <el-button type="info" @click="closePage()">Exit</el-button>
      </div>
    </div>
    <div v-else>
        <div v-if="otherType" style="position: absolute;text-align: left;margin: 80px 0 0 180px;color: red;z-index: 9;">
          <div style="height: 30px;line-height: 30px;">Rev：{{params.revNumber}}</div>
          <div style="height: 30px;line-height: 30px;">Date：{{params.libraryCreateTime}}</div>
        </div>
      <div class="new-item-content" v-if="otherType">
        <!-- base info -->
        <div class="base-info">
          <div>Base Info</div>
          <div>
            <div>
              <span>Product Type：</span>
              <el-select
                ref="productType"
                :class="{ 'velidate-item': checkProductType }"
                @change="productTypeChange"
                @clear="validate('productType')"
                v-model="params.productType"
                placeholder="select"
                clearable
              >
                <el-option
                  v-for="(item, i) in productTypeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <span>Product SubType：</span>
              <el-input
                v-model="params.productSubType"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div
              v-if="
                params.productType != 'CSFMU-B' &&
                params.productType != 'PCG-CSFMU-B' &&
                params.productType != 'Liquid EXT-B'
              "
            >
              <span>Product Name：</span>
              <!-- <el-input
                ref="productName"
                @blur="validate('productName')"
                @change="validate('productName')"
                :class="{'velidate-item':checkProductName}"
                v-model="params.productName"
                placeholder="Please Input"
              ></el-input>-->

              <el-autocomplete
                :class="{ 'velidate-item': checkProductName }"
                ref="productName"
                clearable
                v-model="params.productName"
                :fetch-suggestions="nameSearch"
                placeholder="Please Input"
                @select="handleSelectName"
                @blur="validate('productName')"
                @input="validate('productName')"
                popper-class="select-option"
                :popper-append-to-body="false"
              ></el-autocomplete>
            </div>
            <div v-else>
              <span>Product Name：</span>
              <template
                v-if="
                  params.productId != 1206 &&
                  params.productId != 1207 &&
                  params.productId != 1233 &&
                  params.productId != 1070 &&
                  params.productId != 1160
                "
              >
                <el-input
                  :value="
                    params.itemCode.replace('CSFMU-', '').replace('CSGMU-', '') +
                    ' Extract'
                  "
                  disabled
                ></el-input>
              </template>
              <template v-else>
                <el-input :value="params.productName" disabled></el-input>
              </template>
            </div>
            <div>
              <span>Version Number：</span>
              <el-input
                v-model="params.versionNumber"
                placeholder="Please Input"
              ></el-input>
            </div>

            <template
              v-if="
                params.productId != 1206 &&
                params.productId != 1207 &&
                params.productId != 1233 &&
                params.productId != 1070 &&
                params.productId != 1160
              "
            >
              <div
                v-if="
                  params.productType == 'SDNP(NotShow)' ||
                  params.productType == 'CSFMU-B' ||
                  params.productType == 'PCG-CSFMU-B' ||
                  params.productType == 'Liquid EXT-B'
                "
              >
                <span>Custom Product Name：</span>
                <!-- <el-input
                ref="productName"
                @blur="validate('productName')"
                @change="validate('productName')"
                :class="{'velidate-item':checkProductName}"
                v-model="params.productName"
                placeholder="Please Input"
              ></el-input>-->

                <el-autocomplete
                  :class="{ 'velidate-item': checkProductName }"
                  ref="productName"
                  clearable
                  v-model="params.productName"
                  :fetch-suggestions="nameSearch"
                  placeholder="Please Input"
                  @select="handleSelectName"
                  @blur="validate('productName')"
                  @input="validate('productName')"
                  popper-class="select-option"
                  :popper-append-to-body="false"
                ></el-autocomplete>
              </div>
            </template>

            <div>
              <span>Item Code：</span>
              <!-- <el-input
                ref="itemCode"
                :class="{'velidate-item':checkItemCode}"
                v-model="params.itemCode"
                placeholder="Please Input"
                @blur="validate('itemCode','check')"
                @change="validate('itemCode','change')"
              ></el-input>-->

              <el-autocomplete
                clearable
                ref="itemCode"
                :class="{ 'velidate-item': checkItemCode }"
                v-model="params.itemCode"
                :fetch-suggestions="codeSearch"
                placeholder="Please Input"
                @blur="validate('itemCode', 'check')"
                @input="validate('itemCode', 'change')"
                @select="handleSelectCode"
                popper-class="select-option"
                :popper-append-to-body="false"
              ></el-autocomplete>
            </div>

            <div class="area-input" v-if="productTypeVisible.normal">
              <span>Product Description：</span>
              <el-input
                type="textarea"
                :rows="3"
                resize="none"
                style="width: 600px"
                placeholder="Please Input"
                v-model="params.productDescription"
              ></el-input>
            </div>

            <div
              style="height: 110px; line-height: 110px"
              v-if="productTypeVisible.liquidExt"
            >
              <span>Product Description：</span>
              <el-input
                type="textarea"
                :rows="4"
                resize="none"
                style="width: 600px"
                placeholder="Please Input"
                v-model="params.productDefinition"
              ></el-input>
            </div>

            <div
              style="height: auto"
              v-if="productTypeVisible.shxto || productTypeVisible.ehxto"
            >
              <span>Processing：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in processingArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'processingArr')"
                  >
                    <span
                      @click="tagHandle(processingArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'processingInputVisible',
                          'processingTagInput',
                          i,
                          'processingArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(processingArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(processingArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="processingInputVisible"
                    v-model="processingInputValue"
                    ref="processingTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'processingInputValue',
                        'processingArr',
                        'processingInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'processingInputValue',
                        'processingArr',
                        'processingInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('processingInputVisible', 'processingTagInput')
                    "
                    >+ New Processing</el-button
                  >
                </div>
              </div>
            </div>

            <div>
              <span>Physical properties：</span>
              <el-select
                ref="productType"
                :class="{ 'velidate-item': checkProductType }"
                v-model="params.physicalProperties"
                placeholder="select"
                clearable
              >
                <el-option
                  v-for="(item, i) in physicalPropertiesList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div style="height: auto">
              <span>Confidential：</span>
              <div style="display: inline-block; width: 600px; text-align: left">
                <el-checkbox
                  v-model="params.confidential"
                  placeholder="Please Input"
                ></el-checkbox>
              </div>
            </div>

            <div style="height: auto">
              <span>Custom Formula：</span>
              <div style="display: inline-block; width: 600px; text-align: left">
                <el-checkbox
                  v-model="params.customFormula"
                  placeholder="Please Input"
                ></el-checkbox>
              </div>
            </div>
            <div style="height: auto" v-if="params.customFormula">
              <span>Customer：</span>
              <el-input
                v-model="params.customer"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div style="height: auto">
              <span>Document Notes：</span>
              <el-input
                v-model="params.documentNotes"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div style="height: auto">
              <span>Note by：</span>
              <el-input disabled
                v-model="params.noteBy"
                placeholder="Please Input"
              ></el-input>
            </div>
          <div style="height: auto;margin-top:20px;">
            <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr v-for="(item, baseInfoInputIndex) in BaseInfoCustomFieldAttr" :key="baseInfoInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="Please Input" style="width: 100%" v-model="item.baseInfoInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="Please Input" v-model="item.baseInfoInput2"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addBaseInfoInput(1, 0)">+</el-button>
                    <el-button v-show="BaseInfoCustomFieldAttr.length>1" type="danger" class="button-new-tag" size="small" @click="addBaseInfoInput(0, baseInfoInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          </div>
        </div>
        <!-- General Specification -->
        <div class="general-specification">
          <div>General Specification</div>

          <!-- Liquid EXT -->
          <div v-if="!productTypeVisible.normal">
            <div style="height: auto">
              <span>Common/Other Name：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'commonNameArr')"
                  >
                    <span
                      @click="tagHandle(commonNameArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'commonNameInputVisible',
                          'commonNameSaveTagInput',
                          i,
                          'commonNameArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(commonNameArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(commonNameArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ New CommonName</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <span>Incl Name：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'inclNameArr')"
                  >
                    <span
                      @click="tagHandle(inclNameArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'inclNameInputVisible',
                          'inclNameSaveTagInput',
                          i,
                          'inclNameArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(inclNameArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(inclNameArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ New InclName</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <span>Botanical Name：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'botanicalNameArr')"
                  >
                    <span
                      @click="tagHandle(botanicalNameArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'botanicalNameInputVisible',
                          'botanicalNameSaveTagInput',
                          i,
                          'botanicalNameArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(botanicalNameArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(botanicalNameArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ New botanicalName</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto" v-if="productTypeVisible.liquidExt">
              <span>Major Constituents in Native Herbs：</span>
              <el-input
                v-model="params.majorConstituentsInNativeHerbs"
                placeholder="Please Input"
              ></el-input>
              <!-- <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in majorConstituentsInNativeHerbsArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(majorConstituentsInNativeHerbsArr, tag, i)"
                    @close="handleClose(tag, 'majorConstituentsInNativeHerbsArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="majorConstituentsInNativeHerbsInputVisible"
                    v-model="majorConstituentsInNativeHerbsInputValue"
                    ref="majorConstituentsInNativeHerbsSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'majorConstituentsInNativeHerbsInputValue',
                        'majorConstituentsInNativeHerbsArr',
                        'majorConstituentsInNativeHerbsInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'majorConstituentsInNativeHerbsInputValue',
                        'majorConstituentsInNativeHerbsArr',
                        'majorConstituentsInNativeHerbsInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'majorConstituentsInNativeHerbsInputVisible',
                        'majorConstituentsInNativeHerbsSaveTagInput'
                      )
                    "
                    >+ New MajorConstituentsInNativeHerbs</el-button
                  >
                </div>
              </div> -->
            </div>

            <div style="height: auto">
              <span>Part Used：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'partUsedArr')"
                  >
                    <span
                      @click="tagHandle(partUsedArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'partUsedInputVisible',
                          'partUsedSaveTagInput',
                          i,
                          'partUsedArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(partUsedArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(partUsedArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ New Part Used</el-button
                  >
                </div>
              </div>
            </div>
            
            <div>
                  <span>Country of Origin：</span>
                  <el-input
                    v-model="params.countryOfOrigin"
                    placeholder="Please Input"
                    
                  ></el-input>
                </div>

            <div v-if="productTypeVisible.shxto || productTypeVisible.ehxto">
              <span>Appearance：</span>
              <el-input
                v-model="params.appearance"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div v-if="productTypeVisible.shxto">
              <span>Solubility：</span>
              <el-input
                v-model="params.solubility"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div
              style="height: auto"
              v-if="
                params.productType == 'SDNP-L' ||
                params.productType == 'Liquid EXT-B'
              "
            >
              <span>Percentage ：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <!-- (tag,i) in ingredientsTags(2) -->
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in percentageArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'percentageArr')"
                  >
                    <span
                      @click="tagHandle(percentageArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'percentageInputVisible',
                          'percentageSaveTagInput',
                          i,
                          'percentageArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(percentageArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(percentageArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="percentageInputVisible"
                    v-model="percentageInputValue"
                    ref="percentageSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'percentageInputVisible',
                        'percentageSaveTagInput'
                      )
                    "
                    >+ New Percentage</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <div style="display: inline-block; text-align: left">
                <table style="border: 1px solid #eee">
                  <tr>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                    </td>
                  </tr>
                  <tr
                    v-for="(item, tableInputIndex) in tableInputCustomFieldAttr"
                    :key="tableInputIndex + 1"
                  >
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                      <el-button
                        type="danger"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(0, tableInputIndex)"
                        >-</el-button
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div>
              <span>Color：</span>
              <el-select
                v-model="params.color"
                placeholder="select"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in colorList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <span>Odor：</span>
              <el-input
                v-if="
                  params.productType == 'SDNP-L' || params.productType == 'SHXTO'
                "
                v-model="params.odor"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-if="
                  params.productType != 'SDNP-L' && params.productType != 'SHXTO'
                "
                v-model="params.odor"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Taste：</span>
              <el-input
                v-if="
                  params.productType == 'SDNP-L' || params.productType == 'SHXTO'
                "
                v-model="params.taste"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-if="
                  params.productType != 'SDNP-L' && params.productType != 'SHXTO'
                "
                v-model="params.taste"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div v-if="params.productType == 'SHXTO'">
              <span>Custom #：</span>
              <el-input
                :disabled="colorD"
                v-model="params.customKey1"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                :disabled="colorD"
                v-model="params.customValue1"
                placeholder="Please Value Input"
              ></el-input>
            </div>
            <div v-if="params.productType == 'SHXTO'">
              <span>Custom #：</span>
              <el-input
                :disabled="colorD"
                v-model="params.customKey2"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                :disabled="colorD"
                v-model="params.customValue2"
                placeholder="Please Value Input"
              ></el-input>
            </div>
            <div v-if="params.productType == 'SHXTO'">
              <span style="margin-left: 46px">Custom #：</span>
              <el-input
                :disabled="colorD"
                v-model="params.customKey3"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                :disabled="colorD"
                v-model="params.customValue3"
                placeholder="Please Value Input"
              ></el-input>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button
              >
            </div>

            <div v-if="productTypeVisible.liquidExt">
              <span>Carrier：</span>
              <el-select
                v-model="params.carrierSolvent"
                placeholder="select"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in carrierSolventList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div style="height: auto" v-if="productTypeVisible.liquidExt">
              <span>Preservative：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-input
                    v-model="params.preservative"
                    placeholder="Please Input"
                  ></el-input>
                  <!-- <el-tag
                    :key="i"
                    v-for="(tag, i) in preservativeArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(preservativeArr, tag, i)"
                    @close="handleClose(tag, 'preservativeArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="preservativeInputVisible"
                    v-model="preservativeInputValue"
                    ref="preservativeSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'preservativeInputValue',
                        'preservativeArr',
                        'preservativeInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'preservativeInputValue',
                        'preservativeArr',
                        'preservativeInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'preservativeInputVisible',
                        'preservativeSaveTagInput'
                      )
                    "
                    >+ New preservative</el-button
                  > -->
                </div>
              </div>
            </div>

            <div style="height: auto" v-if="productTypeVisible.liquidExt">
              <span>Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey1"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue1"
                placeholder="Please Value Input"
              ></el-input>
            </div>

            <div style="height: auto" v-if="productTypeVisible.liquidExt">
              <span>Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey2"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue2"
                placeholder="Please Value Input"
              ></el-input>
            </div>
            <div style="height: auto" v-if="productTypeVisible.liquidExt">
              <span style="margin-left: 46px">Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey3"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue3"
                placeholder="Please Value Input"
              ></el-input>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button
              >
            </div>

            <template v-if="params.productType == 'EHXTO'">
              <div>
                <span>Important Constituents In Plant：</span>
                <el-input
                  v-model="params.ehxtoIcip"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Homogeneity：</span>
                <el-input
                  v-model="params.ehxtoHomogeneity"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Extraction Process：</span>
                <el-input
                  v-model="params.ehxtoExtractionProcess"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Solvent for Extraction：</span>
                <el-input
                  v-model="params.ehxtoSolventExtraction"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Carrier：</span>
                <el-input
                  v-model="params.ehxtoCarrier"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Pesticides：</span>
                <el-input
                  v-model="params.ehxtoPesticides"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Certification：</span>
                <el-input
                  v-model="params.ehxtoCertification"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span style="margin-left: 46px">Custom #：</span>
                <el-input
                  v-model="params.ehxtoCustomerKey1"
                  placeholder="Please Name Input"
                  style="width: 200px"
                ></el-input>
                <el-input
                  v-model="params.ehxtoCustomerValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addGenSpecCustomRow(1, 0)"
                  >+</el-button
                >
              </div>
            </template>
          </div>
          <!--Liquid EXT end -->

          <div v-if="productTypeVisible.normal">
            <template
              v-if="
                params.productType == 'FMU-D' || params.productType == 'CSFMU-S'
              "
            >
              <div style="height: auto">
                <span>Ingredients：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <el-tag
                      :key="i"
                      type="info"
                      v-for="(tag, i) in ingredientsArr"
                      :disable-transitions="false"
                      >{{ tag }}</el-tag
                    >
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div style="height: auto">
                <span>Ingredients：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <el-tag
                      :key="i"
                      v-for="(tag, i) in ingredientsArr"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, 'ingredientsArr')"
                    >
                      <span
                        @click="tagHandle(ingredientsArr, tag, i)"
                        style="cursor: pointer"
                        >{{ tag }}</span
                      >
                      <el-button
                        type="primary"
                        style="padding: 0 2px; margin-left: 5px"
                        size="small"
                        @click="
                          showIndexInput(
                            'inputVisible',
                            'saveTagInput',
                            i,
                            'ingredientsArrIndex'
                          )
                        "
                        >+</el-button
                      >
                      <el-button
                        v-show="i > 0"
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-left"
                        @click="leftInputValue(ingredientsArr, i)"
                      ></el-button>
                      <el-button
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-right"
                        @click="rightInputValue(ingredientsArr, i)"
                      ></el-button>
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="inputVisible"
                      v-model="inputValue"
                      ref="saveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'inputValue',
                          'ingredientsArr',
                          'inputVisible'
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'inputValue',
                          'ingredientsArr',
                          'inputVisible'
                        )
                      "
                    ></el-input>
                    <el-button
                      :disabled="ingredientsD"
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="showInput('inputVisible', 'saveTagInput')"
                      >+ New Ingredient</el-button
                    >
                  </div>
                </div>
              </div>
            </template>

            <div v-if="params.productType == 'PCG' || params.productType == 'PCG-CSFMU-S' || params.productType == 'PCG-CSFMU-B'">
              <span>Product grade：</span>
              <div style="width: 600px; display: inline-block; text-align: left">
                Personal Care Grade
              </div>
            </div>

            <!-- <div class="area-input">
              <span>Incl Name：</span>
              <el-input
                type="textarea"
                :rows="3"
                resize="none"
                style="width: 600px;"
                :disabled="inciNameD"
                v-model="params.inciName"
                :placeholder="inciNameD?'disabled':'Please Input'"
              ></el-input>
            </div>-->

            <div style="height: auto">
              <span>Incl Name：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    :disable-transitions="false"
                    closable
                    @close="handleClose(tag, 'inclNameArr')"
                  >
                    <span
                      @click="tagHandle(inclNameArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'inclNameInputVisible',
                          'inclNameSaveTagInput',
                          i,
                          'inclNameArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(inclNameArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(inclNameArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ New InclName</el-button
                  >
                </div>
              </div>
            </div>

            <!-- <div class="area-input">
              <span>Botanical Name：</span>
              <el-input
                :disabled="botanicalNameD"
                type="textarea"
                :rows="3"
                resize="none"
                style="width: 600px;"
                v-model="params.botanicalName"
                :placeholder="botanicalNameD?'disabled':'Please Input'"
              ></el-input>
            </div>-->
            <div style="height: auto">
              <!-- v-if="params.productType=='FMU-D'||params.productType=='CSFMU'" -->
              <template v-if="params.productType == 'FMU-D'">
                <span>Botanical Name：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <!-- v-for="(tag,i) in ingredientsTags(1)" -->
                    <el-tag
                      :key="i"
                      v-for="(tag, i) in botanicalNameArr"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, 'botanicalNameArr',params.productType)"
                    >
                      <span
                        @click="tagHandle(botanicalNameArr, tag, i)"
                        style="cursor: pointer"
                        >{{ tag }}</span
                      >
                      <el-button
                        type="primary"
                        style="padding: 0 2px; margin-left: 5px"
                        size="small"
                        @click="
                          showIndexInput(
                            'botanicalNameInputVisible',
                            'botanicalNameSaveTagInput',
                            i,
                            'botanicalNameArrIndex'
                          )
                        "
                        >+</el-button
                      >
                      <el-button
                        v-show="i > 0"
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-left"
                        @click="leftInputValue(botanicalNameArr, i)"
                      ></el-button>
                      <el-button
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-right"
                        @click="rightInputValue(botanicalNameArr, i)"
                      ></el-button>
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="botanicalNameInputVisible"
                      v-model="botanicalNameInputValue"
                      ref="botanicalNameSaveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'botanicalNameInputValue',
                          'botanicalNameArr',
                          'botanicalNameInputVisible',params.productType
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'botanicalNameInputValue',
                          'botanicalNameArr',
                          'botanicalNameInputVisible',params.productType
                        )
                      "
                    ></el-input>
                    <el-button
                      :disabled="botanicalNameD"
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="
                        showInput(
                          'botanicalNameInputVisible',
                          'botanicalNameSaveTagInput'
                        )
                      "
                      >+ New botanicalName</el-button
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- Botanical Name== -->
                <span>Botanical Name：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <el-tag
                      :key="i"
                      v-for="(tag, i) in botanicalNameArr"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, 'botanicalNameArr',params.productType)"
                    >
                      <span
                        @click="tagHandle(botanicalNameArr, tag, i)"
                        style="cursor: pointer"
                        >{{ tag }}</span
                      >
                      <el-button
                        type="primary"
                        style="padding: 0 2px; margin-left: 5px"
                        size="small"
                        @click="
                          showIndexInput(
                            'botanicalNameInputVisible',
                            'botanicalNameSaveTagInput',
                            i,
                            'botanicalNameArrIndex'
                          )
                        "
                        >+</el-button
                      >
                      <el-button
                        v-show="i > 0"
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-left"
                        @click="leftInputValue(botanicalNameArr, i)"
                      ></el-button>
                      <el-button
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-right"
                        @click="rightInputValue(botanicalNameArr, i)"
                      ></el-button>
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="botanicalNameInputVisible"
                      v-model="botanicalNameInputValue"
                      ref="botanicalNameSaveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'botanicalNameInputValue',
                          'botanicalNameArr',
                          'botanicalNameInputVisible',params.productType
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'botanicalNameInputValue',
                          'botanicalNameArr',
                          'botanicalNameInputVisible',params.productType
                        )
                      "
                    ></el-input>
                    <el-button
                      :disabled="botanicalNameD"
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="
                        showInput(
                          'botanicalNameInputVisible',
                          'botanicalNameSaveTagInput'
                        )
                      "
                      >+ New botanicalName</el-button
                    >
                  </div>
                </div>
              </template>
            </div>

            <!-- <div>
              <span>Common/Other Name：</span>
              <el-input
                :disabled="commonOtherNameD"
                v-model="params.commonOtherName"
                :placeholder="commonOtherNameD?'disabled':'Please Input'"
              ></el-input>
            </div>-->

            <div style="height: auto">
              <span>Common/Other Name：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonOtherNameArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'commonOtherNameArr')"
                  >
                    <span
                      @click="tagHandle(commonOtherNameArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'commonOtherNameInputVisible',
                          'commonOtherNameSaveTagInput',
                          i,
                          'commonOtherNameArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(commonOtherNameArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(commonOtherNameArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="commonOtherNameInputVisible"
                    v-model="commonOtherNameInputValue"
                    ref="commonOtherNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonOtherNameInputValue',
                        'commonOtherNameArr',
                        'commonOtherNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonOtherNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonOtherNameInputVisible',
                        'commonOtherNameSaveTagInput'
                      )
                    "
                    >+ New CommonOtherName</el-button
                  >
                </div>
              </div>
            </div>



            <div style="height: auto">
              <template v-if="params.productType == 'FMU-D'">
                <span>Part Used：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <!-- (tag,i) in ingredientsTags(2) -->
                    <el-tag
                      :key="i"
                      v-for="(tag, i) in partUsedArr"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, 'partUsedArr')"
                    >
                      <span
                        @click="tagHandle(partUsedArr, tag, i)"
                        style="cursor: pointer"
                        >{{ tag }}</span
                      >
                      <el-button
                        type="primary"
                        style="padding: 0 2px; margin-left: 5px"
                        size="small"
                        @click="
                          showIndexInput(
                            'partUsedInputVisible',
                            'partUsedSaveTagInput',
                            i,
                            'partUsedArrIndex'
                          )
                        "
                        >+</el-button
                      >
                      <el-button
                        v-show="i > 0"
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-left"
                        @click="leftInputValue(partUsedArr, i)"
                      ></el-button>
                      <el-button
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-right"
                        @click="rightInputValue(partUsedArr, i)"
                      ></el-button>
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="partUsedInputVisible"
                      v-model="partUsedInputValue"
                      ref="partUsedSaveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'partUsedInputValue',
                          'partUsedArr',
                          'partUsedInputVisible'
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'partUsedInputValue',
                          'partUsedArr',
                          'partUsedInputVisible'
                        )
                      "
                    ></el-input>
                    <el-button
                      :disabled="partUsedD"
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="
                        showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                      "
                      >+ New Part Used</el-button
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <span>Part Used：</span>
                <div
                  style="display: inline-block; width: 615px; text-align: left"
                >
                  <div
                    style="
                      display: flex;
                      justify: flex-start;
                      flex-wrap: wrap;
                      align-items: center;
                    "
                  >
                    <el-tag
                      :key="i"
                      v-for="(tag, i) in partUsedArr"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, 'partUsedArr')"
                    >
                      <span
                        @click="tagHandle(partUsedArr, tag, i)"
                        style="cursor: pointer"
                        >{{ tag }}</span
                      >
                      <el-button
                        type="primary"
                        style="padding: 0 2px; margin-left: 5px"
                        size="small"
                        @click="
                          showIndexInput(
                            'partUsedInputVisible',
                            'partUsedSaveTagInput',
                            i,
                            'partUsedArrIndex'
                          )
                        "
                        >+</el-button
                      >
                      <el-button
                        v-show="i > 0"
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-left"
                        @click="leftInputValue(partUsedArr, i)"
                      ></el-button>
                      <el-button
                        type="primary"
                        style="padding: 0"
                        size="small"
                        icon="el-icon-arrow-right"
                        @click="rightInputValue(partUsedArr, i)"
                      ></el-button>
                    </el-tag>
                    <el-input
                      class="input-new-tag"
                      v-if="partUsedInputVisible"
                      v-model="partUsedInputValue"
                      ref="partUsedSaveTagInput"
                      size="small"
                      @keyup.enter.native="
                        handleInputConfirm(
                          'partUsedInputValue',
                          'partUsedArr',
                          'partUsedInputVisible'
                        )
                      "
                      @blur="
                        handleInputConfirm(
                          'partUsedInputValue',
                          'partUsedArr',
                          'partUsedInputVisible'
                        )
                      "
                    ></el-input>
                    <el-button
                      :disabled="partUsedD"
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="
                        showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                      "
                      >+ New Part Used</el-button
                    >
                  </div>
                </div>
                
                
              </template>
              
            </div>
            <div>
                  <span>Country of Origin：</span>
                  <el-input
                    v-model="params.countryOfOrigin"
                    placeholder="Please Input"
                    
                  ></el-input>
                </div>

           
            <div
              style="height: auto"
              v-if="
                params.productType == 'SDNP' ||
                params.productType == 'CSFMU-B' ||
                params.productType == 'FMU-D' ||
                params.productType == 'FMU-D' ||
                params.productType == 'PCG-CSFMU-B'
              "
            >
              <span>Percentage ：</span>
              <div style="display: inline-block; width: 615px; text-align: left">
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <!-- (tag,i) in ingredientsTags(2) -->
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in percentageArr"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, 'percentageArr')"
                  >
                    <span
                      @click="tagHandle(percentageArr, tag, i)"
                      style="cursor: pointer"
                      >{{ tag }}</span
                    >
                    <el-button
                      type="primary"
                      style="padding: 0 2px; margin-left: 5px"
                      size="small"
                      @click="
                        showIndexInput(
                          'percentageInputVisible',
                          'percentageSaveTagInput',
                          i,
                          'percentageArrIndex'
                        )
                      "
                      >+</el-button
                    >
                    <el-button
                      v-show="i > 0"
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-left"
                      @click="leftInputValue(percentageArr, i)"
                    ></el-button>
                    <el-button
                      type="primary"
                      style="padding: 0"
                      size="small"
                      icon="el-icon-arrow-right"
                      @click="rightInputValue(percentageArr, i)"
                    ></el-button>
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="percentageInputVisible"
                    v-model="percentageInputValue"
                    ref="percentageSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'percentageInputValue',
                        'percentageArr',
                        'percentageInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'percentageInputVisible',
                        'percentageSaveTagInput'
                      )
                    "
                    >+ New Percentage</el-button
                  >
                </div>
              </div>
            </div>

            <div style="height: auto">
              <div style="display: inline-block; text-align: left">
                <table style="border: 1px solid #eee">
                  <tr>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="params.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                    </td>
                  </tr>
                  <tr
                    v-for="(item, tableInputIndex) in tableInputCustomFieldAttr"
                    :key="tableInputIndex + 1"
                  >
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput1"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput2"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput3"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput4"
                      ></el-input>
                    </td>
                    <td style="border: 1px solid #eee">
                      <el-input
                        placeholder="Please Input"
                        style="width: 100%"
                        v-model="item.tableInput5"
                      ></el-input>
                    </td>
                    <td>
                      <el-button
                        type="primary"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(1, 0)"
                        >+</el-button
                      >
                      <el-button
                        type="danger"
                        class="button-new-tag"
                        size="small"
                        @click="addTableInput(0, tableInputIndex)"
                        >-</el-button
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div style="clear: both; height: 10px"></div>

            <div>
              <span>Extraction Ratio：</span>
              <el-input
                v-model="params.extractionRatio"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Important Constituents In Plant：</span>
              <el-input
                v-model="params.importantConstitueInPlant"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Appearance：</span>
              <el-select
                v-model="params.appearance"
                placeholder="select"
                clearable
                @change="appearanceTypeChange"
              >
                <el-option
                  v-for="(item, i) in appearanceList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <span>Solubility：</span>
              <el-input
                v-model="params.solubility"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Homogeneity：</span>
              <div style="width: 600px; display: inline-block; text-align: left">
                Completely homogenous
              </div>
            </div>

            <div>
              <span>Extraction Process：</span>
              <!-- <div style="width: 600px; display: inline-block; text-align: left">
                Controlled-temperature water extraction process, spray dried
              </div> -->
              <el-input
                v-model="params.ehxtoExtractionProcess"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div
              v-if="
                params.productType == 'SDNP' ||
                params.productType == 'CSFMU-B' ||
                params.productType == 'CSFMU-S' ||
                params.productType == 'F&V'
              "
            >
              <span>Processing Aid：</span>
              <el-input
                v-model="params.processingAid"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Solvent for Extraction：</span>
              <el-input
                v-model="params.solventForExtracti"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Carrier：</span>
              <el-select
                v-model="params.carrier"
                placeholder="select"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in carrierList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div>
              <span>Pesticides：</span>
              <el-input
                v-model="params.pesticides"
                placeholder="Please Input"
              ></el-input>
            </div>

            <div>
              <span>Certification：</span>
              <el-input
                v-model="params.certification"
                placeholder="Please Input"
              ></el-input>
            </div>
            <div>
              <span>Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey1"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue1"
                placeholder="Please Value Input"
              ></el-input>
            </div>
            <div>
              <span>Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey2"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue2"
                placeholder="Please Value Input"
              ></el-input>
            </div>
            <div>
              <span style="margin-left: 46px">Custom #：</span>
              <el-input
                v-model="params.generalSpecificationKey3"
                placeholder="Please Name Input"
                style="width: 200px"
              ></el-input>
              <el-input
                v-model="params.generalSpecificationValue3"
                placeholder="Please Value Input"
              ></el-input>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button
              >
            </div>
          </div>
          <div
            v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
            :key="genSpecIndex + 1"
          >
            <span>Custom #：</span>
            <el-input
              v-model="item.customKey"
              placeholder="Please Name Input"
              style="width: 200px"
            ></el-input>
            <el-input
              v-model="item.customValue"
              placeholder="Please Value Input"
            ></el-input>
            <el-button
              type="primary"
              class="button-new-tag"
              size="small"
              @click="addGenSpecCustomRow(1, genSpecIndex)"
              >+</el-button
            >
            <el-button
              type="danger"
              class="button-new-tag"
              size="small"
              @click="addGenSpecCustomRow(0, genSpecIndex)"
              >-</el-button
            >
          </div>
        </div>

        <!-- SHXTO Test-->
        <div class="analysis" v-if="productTypeVisible.shxto">
          <div>Analysis</div>
          <div>
            <div class="specification">
              <div>Limit</div>
              <div style="display: flex; align-items: center">
                <span>Moisture：</span>
                <el-input
                  v-model="params.testLimitMoisture"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">≤</div>
                  </template>
                  <template slot="append">
                    <div style="width:100%;">%</div>
                  </template> -->
                </el-input>
              </div>

              <div>
                <span>Specific Gravity：</span>
                <el-input
                  v-model="params.testLimitSpecificaGravity"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Refractive Index：</span>
                <el-input
                  v-model="params.testLimitRefractiveIndex"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Optical Rotation：</span>
                <el-input
                  v-model="params.testLimitOpticalRotation"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Total Heavy Metals：</span>
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  v-model="params.testLimitHeavyMetals"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Arsenic (As)：</span>
                <el-input
                  v-model="params.testLimitArsenic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Cadmium (Cd) ：</span>
                <el-input
                  v-model="params.testLimitCadmium"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Lead (Pd)：</span>
                <el-input
                  v-model="params.testLimitLead"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Mercury (Hg)：</span>
                <el-input
                  v-model="params.testLimitMercury"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestLimitCustomerKey1"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestLimitCustomerValue1"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestLimitCustomerKey2"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestLimitCustomerValue2"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestLimitCustomerKey3"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestLimitCustomerValue3"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestLimitCustomerKey4"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestLimitCustomerValue4"
                  placeholder="Please Value Input"
                ></el-input>
              </div>

              <div
                v-for="(item, analysisIndex) in analysisCustomFieldAttr"
                :key="analysisIndex + 1"
              >
                <span
                  ><el-input
                    v-model="item.customKey"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="item.customValue"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
            </div>
            <div class="test-method">
              <div>Test Method</div>
              <div>
                <span>Moisture：</span>
                <el-input
                  v-model="params.testMethodMeshSize"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Specific Gravity：</span>
                <el-input
                  v-model="params.testMethodSpecificaGravity"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Refractive Index：</span>
                <el-input
                  v-model="params.testMethodRefractiveIndex"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Optical Rotation：</span>
                <el-input
                  v-model="params.testMethodOpticalRotation"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Heavy Metals：</span>
                <el-input
                  v-model="params.testMethodHeavyMetals"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Arsenic (As)：</span>
                <el-input
                  v-model="params.testMethodArsenic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Cadmium (Cd) ：</span>
                <el-input
                  v-model="params.testMethodCadmium"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Lead (Pd)：</span>
                <el-input
                  v-model="params.testMethodLead"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Mercury (Hg)：</span>
                <el-input
                  v-model="params.testMethodMercury"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestMethodCustomerKey1"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestMethodCustomerValue1"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestMethodCustomerKey2"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestMethodCustomerValue2"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestMethodCustomerKey3"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestMethodCustomerValue3"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span
                  ><el-input
                    v-model="params.ehxtoTestMethodCustomerKey4"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：</span
                >
                <el-input
                  v-model="params.ehxtoTestMethodCustomerValue4"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button
                >
              </div>
              <div
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto + 1"
              >
                <span>
                  <el-input
                    v-model="item.customKey1"
                    placeholder="Please Name Input"
                    style="width: 160px"
                  ></el-input
                  >：
                </span>
                <el-input
                  v-model="item.customValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- SHXTO END-->

        <!-- Liquid EXT Analysis-->
        <div class="analysis" v-if="productTypeVisible.liquidExt">
          <div>Analysis</div>
          <div>
            <div class="specification">
              <div>Range</div>
              <div style="display: flex; align-items: center">
                <span>Bioactives level：</span>
                <el-input
                  v-model="params.analysisRangeBioactivesLevel"
                  placeholder="Please Input"
                >
                  <template slot="prepend" v-if="params.productType=='SDNP-L'">
                    <div style="width: 100%">≥</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>
                  <el-input
                    style="width: 150px"
                    v-model="params.liquidExtCustomerKey1"
                    placeholder="Please Input"
                  ></el-input
                  >：
                </span>

                <el-input
                  v-model="params.liquidExtCustomerValue1"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>
                  <el-input
                    style="width: 150px"
                    v-model="params.liquidExtCustomerKey3"
                    placeholder="Please Input"
                  ></el-input
                  >：
                </span>
                <el-input
                  v-model="params.liquidExtCustomerValue3"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>
                  <el-input
                    style="width: 150px"
                    v-model="params.liquidExtCustomerKey4"
                    placeholder="Please Input"
                  ></el-input
                  >：
                </span>
                <el-input
                  v-model="params.liquidExtCustomerValue4"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>
                  <el-input
                    style="width: 150px"
                    v-model="params.liquidExtCustomerKey5"
                    placeholder="Please Name Input"
                  ></el-input
                  >：
                </span>
                <el-input
                  v-model="params.liquidExtCustomerValue5"
                  placeholder="Please Value Input"
                ></el-input>
              </div>

              <div
                v-for="(item, analysisIndexLext) in analysisCustomFieldAttr"
                :key="analysisIndexLext + 1"
              >
                <span>
                  <el-input
                    style="width: 150px"
                    v-model="item.customKey"
                    placeholder="Please Name Input"
                  ></el-input
                  >：
                </span>
                <el-input
                  v-model="item.customValue"
                  placeholder="Please Value Input"
                ></el-input>
              </div>

              <div>
                <span>pH：</span>
                <el-input
                  v-model="params.analysisRangePh"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Heavy Metal：</span>
                <el-input
                  v-model="params.analysisRangeHeavyMetal"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Arsenic：</span>
                <el-input
                  v-model="params.analysisArsenic"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Cadmium：</span>
                <el-input
                  v-model="params.analysisCadmium"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Lead：</span>
                <el-input
                  v-model="params.analysisLead"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Mercury：</span>
                <el-input
                  v-model="params.analysisMercury"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div>
                <span>Pesticide：</span>
                <el-input
                  v-model="params.analysisRangePesticide"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Plate Count：</span>
                <el-input
                  v-model="params.analysisRangeTotalPlateCount"
                  placeholder="Please Input"
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Yeast & Mold：</span>
                <el-input
                  v-model="params.analysisRangeYeastMold"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>E. Coli：</span>
                <el-input
                  v-model="params.specificationEcoli"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Salmonella：</span>
                <el-input
                  v-model="params.specificationSalmonella"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Staph Aureus：</span>
                <el-input
                  v-model="params.specificationStaphAureus"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Coliform：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
                -->
                <el-input
                  @blur="checktestMethodTotalColiforms()"
                  v-model="params.testMethodTotalColiforms"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">MPN/g</div>
                  </template>
                </el-input>
              </div>
            </div>

            <div class="test-method">
              <div>Test Method</div>
              <div>
                <span>Bioactives level：</span>
                <!-- <el-input disabled v-model="params.analysisMethodPh" placeholder="Please Input"></el-input> -->
              </div>

              <div>
                <!-- <el-input
                  v-model="params.liquidExtCustomerKey2"
                  placeholder="Please Input"
                ></el-input> -->
                <span>{{ params.liquidExtCustomerKey1 }}：</span>
                <el-input
                  v-model="params.liquidExtCustomerValue2"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>{{ params.liquidExtCustomerKey3 }}：</span>
                <el-input
                  v-model="params.liquidExtCustomerValue3TM"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>{{ params.liquidExtCustomerKey4 }}：</span>
                <el-input
                  v-model="params.liquidExtCustomerValue4TM"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>{{ params.liquidExtCustomerKey5 }}：</span>
                <el-input
                  v-model="params.liquidExtCustomerValue5TM"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button
                >
              </div>
              <div
                v-for="(item, analysisIndexext1) in analysisCustomFieldAttr"
                :key="analysisIndexext1 + 1"
              >
                <span>{{ item.customKey1 }}：</span>
                <el-input
                  v-model="item.customValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexext1)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexext1)"
                  >-</el-button
                >
              </div>

              <div>
                <span>pH：</span>
                <el-input v-model="params.analysisMethodPh" placeholder="USP<791>"></el-input>
              </div>
              <div>
                <span>Heavy Metal：</span>
                <el-input v-model="params.analysisMethodHeavyMetal" placeholder="USP <231>"></el-input>
              </div>

              <div>
                <span>Arsenic：</span>
                <el-input v-model="params.analysisMethodArsenic" placeholder="Please Input">
                </el-input>
              </div>
              <div>
                <span>Cadmium：</span>
                <el-input v-model="params.analysisMethodCadmium" placeholder="Please Input">
                </el-input>
              </div>
              <div>
                <span>Lead：</span>
                <el-input v-model="params.analysisMethodLead" placeholder="Please Input">
                </el-input>
              </div>
              <div>
                <span>Mercury：</span>
                <el-input v-model="params.analysisMethodMercury" placeholder="Please Input">
                </el-input>
              </div>

              <div>
                <span>Pesticide：</span>
                <el-input v-model="params.analysisMethodPesticide" placeholder="USP<565>"></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Plate Count：</span>
                <el-input v-model="params.analysisMethodTotalPlateCount" placeholder="FDA-BAM" onkeyup="value=value.replace(/[^\d.]|/g,'')"></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Yeast & Mold：</span>
                <el-input v-model="params.analysisMethodYeastMold" placeholder="FDA-BAM" onkeyup="value=value.replace(/[^\d.]|/g,'')"></el-input>
              </div>
              <div>
                <span>E. Coli：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div>
              </div>
              <div>
                <span>Salmonella：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div>
              </div>
              <div>
                <span>Staph Aureus：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div>
              </div>
              <div>
                <span>Total Coliform：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Liquid EXT END-->

        <!-- EHXTO Test Analysis-->
        <div class="analysis" v-if="productTypeVisible.ehxto">
          <div>
            Test
            <el-button
              type="primary"
              class="button-new-tag"
              size="small"
              @click="addSHTest"
              >+ Add Test</el-button
            >
          </div>
          <div
            style="display: flex; margin-top: 20px"
            v-for="(item, index) in shTestArr"
            :key="index"
          >
            <div
              class="specification"
              style="width: 50%; margin-right: 120px; border: 1px solid #e9e9e9"
            >
              <div>Limit</div>
              <div style="display: flex; align-items: center">
                <span>Acid Value：</span>
                <el-input
                  v-model="item.testLimitAcidValue"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">mg KOH/G</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Peroxide Value：</span>
                <el-input
                  v-model="item.testLimitPeroxideValue"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">meq/kg</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Saponification Value：</span>
                <el-input
                  v-model="item.testLimitSaponificationValue"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">mg KOH/g</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Unsaponifiable Matter：</span>
                <el-input
                  v-model="item.testLimitUnsaponifiableMaster"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Moisture & Volatiles：</span>
                <el-input
                  v-model="item.testLimitMoistureVolatiles"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Heavy Metals：</span>
                <el-input
                  v-model="item.testLimitHeavyMetals"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Arsenic：</span>
                <el-input
                  v-model="item.testLimitArsenic"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Cadmium：</span>
                <el-input
                  v-model="item.testLimitCadmium"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Lead：</span>
                <el-input v-model="item.testLimitLead" placeholder="Please Input">
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Mercury：</span>
                <el-input
                  v-model="item.testLimitMercury"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width: 100%">NMT</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Pesticides：</span>
                <el-input
                  v-model="item.testLimitPesticides"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.testLimitCustomKey1"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.testLimitCustomKey1Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.testLimitCustomKey2"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.testLimitCustomKey2Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div
                style="display: flex; align-items: center"
                v-for="(testItem, testIndex) in item.testCustomFieldAttr"
                :key="testIndex + 1"
              >
                <span>Custom #：</span>
                <el-input
                  v-model="testItem.customKey"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="testItem.customValue"
                  style="width: 160px"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
            </div>

            <div
              class="test-method"
              style="width: 50%; border: 1px solid #e9e9e9"
            >
              <div>Method</div>
              <div style="display: flex; align-items: center">
                <span>Acid Value：</span>
                <el-input
                  v-model="item.testMethodAcidValue"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Peroxide Value：</span>
                <el-input
                  v-model="item.testMethodPeroxideValue"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Saponification Value：</span>
                <el-input
                  v-model="item.testMethodSaponificationValue"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Unsaponifiable Matter：</span>
                <el-input
                  v-model="item.testMethodUnsaponifiableMaster"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Moisture & Volatiles：</span>
                <el-input
                  v-model="item.testMethodMoistureVolatiles"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Heavy Metals：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  USP &lt;231&gt;
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <span>Arsenic：</span>
                <el-input
                  v-model="item.testMethodArsenic"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Cadmium：</span>
                <el-input
                  v-model="item.testMethodCadmium"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Lead：</span>
                <el-input
                  v-model="item.testMethodLead"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Mercury：</span>
                <!-- <el-input disabled v-model="item.testMethodMercury" placeholder="Please Input"></el-input> -->
                <el-input
                  placeholder="Please Input"
                  v-model="item.testMethodMercury"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Pesticides：</span>
                <el-input
                  v-model="item.testMethodPesticides"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.testLimitCustomKey1"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.testMethodCustomKey1Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.testLimitCustomKey2"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.testMethodCustomKey2Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addTestCustomRow(1, 0, index)"
                  >+</el-button
                >
              </div>
              <div
                style="display: flex; align-items: center"
                v-for="(testItem, testIndex1) in item.testCustomFieldAttr"
                :key="testIndex1 + 1"
              >
                <span>Custom #：</span>
                <el-input
                  v-model="testItem.customKey"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="testItem.customValue1"
                  style="width: 160px"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addTestCustomRow(1, testIndex1, index)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addTestCustomRow(0, testIndex1, index)"
                  >-</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- EHXTO END-->

        <!-- EHXTO Fatty Analysis-->
        <div class="analysis" v-if="productTypeVisible.ehxto">
          <div>
            Fatty Acid Composition
            <el-button
              type="primary"
              class="button-new-tag"
              size="small"
              @click="addfattyAcidComposition"
              >+ Add Composition</el-button
            >
          </div>
          <div
            style="display: flex; margin-top: 20px"
            v-for="(item, index) in fattyAcidCompositionArr"
            :key="index"
          >
            <div
              class="specification"
              style="width: 50%; margin-right: 120px; border: 1px solid #e9e9e9"
            >
              <div>Limit</div>
              <!-- <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey1"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryLimitKey1Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey2"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryLimitKey2Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
              </div> -->

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C8:0 Caprylic acid：</span>
                <el-input
                  v-model="item.fattryLimitCaprylic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C10:0 Capric acid：</span>
                <el-input
                  v-model="item.fattryLimitCapric"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C12:0 Lauric acid：</span>
                <el-input
                  v-model="item.fattryLimitLauric"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:0 Myristic acid：</span>
                <el-input
                  v-model="item.fattryLimitMyristic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:1 Myristoleic acid：</span>
                <el-input
                  v-model="item.fattryLimitMyristoleic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16:0 Palmitic acid：</span>
                <el-input
                  v-model="item.fattryLimitPalmitica"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16:1 Palmitoleic acid：</span>
                <el-input
                  v-model="item.fattryLimitPalmitoleic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:0 Stearic acid：</span>
                <el-input
                  v-model="item.fattryLimitStearic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:1 Oleic acid：</span>
                <el-input
                  v-model="item.fattryLimitOleicVaccenic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:2 Linoleic acid：</span>
                <el-input
                  v-model="item.fattryLimitLinoleic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 α-Linolenic acid：</span>
                <el-input
                  v-model="item.fattryLimitALinolenic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 γ-Linoleic acid：</span>
                <el-input
                  v-model="item.fattryLimitLinoleicacid"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 Punicic acid：</span>
                <el-input
                  v-model="item.fattryLimitPunicicacid"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 20:0 Arachidic acid：</span>
                <el-input
                  v-model="item.fattryLimitArachidic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:4 Arachidonic acid：</span>
                <el-input
                  v-model="item.fattryLimitArachidonic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:5 Eicosapentaenoic acid：</span>
                <el-input
                  v-model="item.fattryLimitEicosapentaenoic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 22:6 Docosahexaenoic acid：</span>
                <el-input
                  v-model="item.fattryLimitDocosahexaenoic"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey3"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryLimitKey3Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey4"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryLimitKey4Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey5"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryLimitKey5Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div
                style="display: flex; align-items: center"
                v-for="(fattryItem, fattryIndex) in item.fattyCustomFieldAttr"
                :key="fattryIndex + 1"
              >
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="fattryItem.customKey"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="fattryItem.customValue"
                  style="width: 160px"
                  placeholder="Please Value Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Others：</span>
                <el-input
                  v-model="item.fatterLimitOthers"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
            </div>

            <div
              class="test-method"
              style="width: 50%; border: 1px solid #e9e9e9"
            >
              <div>Method</div>
              <!-- <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey1" 
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryMethodKey1Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey2" 
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryMethodKey2Value"
                  placeholder="Please Input"
                  style="width: 160px"
                ></el-input>
              </div> -->

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C8:0 Caprylic acid：</span>
                <el-input
                  v-model="item.fattryMethodCaprylic"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C10:0 Capric acid：</span>
                <el-input
                  v-model="item.fattryMethodCapric"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C12:0 Lauric acid：</span>
                <el-input
                  v-model="item.fattryMethodLauric"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:0 Myristic acid：</span>
                <el-input
                  v-model="item.fattryMethodMyristic"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C14:1 Myristoleic acid：</span>
                <el-input
                  v-model="item.fattryMethodMyristoleic"
                  placeholder="Please Input"
                >
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16:0 Palmitic acid：</span>
                <el-input
                  v-model="item.fattryMethodPalmitica"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C16:1 Palmitoleic acid：</span>
                <el-input
                  v-model="item.fattryMethodPalmitoleic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:0 Stearic acid：</span>
                <el-input
                  v-model="item.fattryMethodStearic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:1 Oleic acid：</span>
                <el-input
                  v-model="item.fattryMethodOleicVaccenic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:2 Linoleic acid：</span>
                <el-input
                  v-model="item.fattryMethodLinoleic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 α-Linolenic acid：</span>
                <el-input
                  v-model="item.fattryMethodALinolenic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 γ-Linoleic acid：</span>
                <el-input
                  v-model="item.fattryMethodLinoleicacid"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C18:3 Punicic acid：</span>
                <el-input
                  v-model="item.fattryMethodPunicicacid"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 20:0 Arachidic acid：</span>
                <el-input
                  v-model="item.fattryMethodArachidic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:4 Arachidonic acid：</span>
                <el-input
                  v-model="item.fattryMethodArachidonic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C20:5 Eicosapentaenoic acid：</span>
                <el-input
                  v-model="item.fattryMethodEicosapentaenoic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">C 22:6 Docosahexaenoic acid：</span>
                <el-input
                  v-model="item.fattryMethodDocosahexaenoic"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey3"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryMethodKey3Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey4"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryMethodKey4Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="item.fattryLimitKey5"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="item.fattryMethodKey5Value"
                  placeholder="Please Value Input"
                  style="width: 160px"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(1, 0, index)"
                  >+</el-button
                >
              </div>
              <div
                style="display: flex; align-items: center"
                v-for="(fattryItem, fattryIndex1) in item.fattyCustomFieldAttr"
                :key="fattryIndex1 + 1"
              >
                <span class="acidSpanClass">Custom #：</span>
                <el-input
                  v-model="fattryItem.customKey"
                  placeholder="Please Name Input"
                  style="width: 160px"
                ></el-input>
                <el-input
                  v-model="fattryItem.customValue1"
                  style="width: 160px"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(1, fattryIndex1, index)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(0, fattryIndex1, index)"
                  >-</el-button
                >
              </div>

              <div style="display: flex; align-items: center">
                <span class="acidSpanClass">Others：</span>
                <el-input
                  v-model="item.fatterMethodOthers"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <!-- EHXTO END-->

        <!-- Analysis -->
        <div class="analysis" v-if="productTypeVisible.normal">
          <div>Analysis</div>
          <div>
            <div class="specification">
              <div>Specification</div>
              <div>
                <span>STANDARD 1：</span>
                <el-input
                  v-model="params.specificationStandard1"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>STANDARD 2：</span>
                <el-input
                  v-model="params.specificationStandard2"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>STANDARD 3：</span>
                <el-input
                  v-model="params.specificationStandard3"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Mesh Size：</span>
                <el-select
                  v-model="params.specificationMeshSize"
                  placeholder="select"
                  clearable
                  filterable
                  allow-create
                >
                  <el-option
                    v-for="(item, i) in meshSizeList"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div style="display: flex; align-items: center">
                <span>Loss on Drying：</span>
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  v-model="params.specificationLossonDrying"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">%</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Bulk Density：</span>
                <el-input
                  v-model="params.specificationBulkDensity"
                  placeholder="Please Input"
                >
                  <template slot="append">
                    <div style="width: 100%">g/mL</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>Identification：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  Conforms
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Heavy Metals：</span>
                <el-input
                  v-model="params.specificationTotalHeavyMetals"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div style="display: flex; align-items: center">
                <span>Arsenic：</span>
                <el-input
                  v-model="params.specificationArsenic"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">&lt;</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Cadmium：</span>
                <el-input
                  v-model="params.specificationCadmium"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">&lt;</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Lead：</span>
                <el-input
                  v-model="params.specificationLead"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">&lt;</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Mercury：</span>
                <el-input
                  v-model="params.specificationMercury"
                  placeholder="Please Input"
                >
                  <!-- <template slot="prepend">
                    <div style="width:100%;">&lt;</div>
                  </template> -->
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
              </div>

              <div>
                <el-input
                  v-model="params.specificationCustomerKey1"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="params.specificationCustomerValue1"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey2"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="params.specificationCustomerValue2"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey3"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="params.specificationCustomerValue3"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey4"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="params.specificationCustomerValue4"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey5"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="params.specificationCustomerValue5"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <div
                v-for="(item, analysisIndex) in analysisCustomFieldAttr"
                :key="analysisIndex + 1"
              >
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="item.customValue"
                  placeholder="Please Value Input"
                ></el-input>
              </div>

              <div>
                <span>Color：</span>
                <!-- <el-input v-model="params.specificationColor" placeholder="Please Input"></el-input> -->
                <el-select
                  v-model="params.specificationColor"
                  placeholder="select"
                  clearable
                  filterable
                  allow-create
                >
                  <el-option
                    v-for="(item, i) in colorList"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <span>Odor：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">Compares to standard</div> -->
                <el-input
                  v-model="params.specificationOrdor"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Taste：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">Compares to standard</div> -->
                <el-input
                  v-model="params.specificationTaste"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
            <div class="test-method">
              <div>Test Method</div>
              <div>
                <span>STANDARD 1：</span>
                <el-input
                  v-model="params.testMethodStandard1"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>STANDARD 2：</span>
                <el-input
                  v-model="params.testMethodStandard2"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>STANDARD 3：</span>
                <el-input
                  v-model="params.testMethodStandard3"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>MESH：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  Metric Sieve
                </div>
              </div>
              <div>
                <span>Loss on Drying：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  Draco-1.1.1.0
                </div>
              </div>
              <div>
                <span>Bulk Density：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  USP &lt;616&gt;
                </div>
              </div>
              <div>
                <span>Identification：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  HPTLC
                </div>
              </div>
              <div>
                <span>Total Heavy Metals：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  USP &lt;231&gt;
                </div>
              </div>
              <div>
                <span>Arsenic：</span>
                <el-input
                  v-model="params.testMethodArsenic"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Cadmium：</span>
                <el-input
                  v-model="params.testMethodCadmium"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Lead：</span>
                <el-input
                  v-model="params.testMethodLead"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Mercury：</span>
                <el-input
                  v-model="params.testMethodMercury"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <el-input
                  v-model="params.specificationCustomerKey1"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.testMethodCustomerValue1"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <el-input
                  v-model="params.specificationCustomerKey2"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.testMethodCustomerValue2"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey3"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.testMethodCustomerValue3"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey4"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.testMethodCustomerValue4"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <el-input
                  v-model="params.specificationCustomerKey5"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.testMethodCustomerValue5"
                  placeholder="Please Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button
                >
              </div>
              <div
                v-for="(item, analysisIndex1) in analysisCustomFieldAttr"
                :key="analysisIndex1 + 1"
              >
                <el-input
                  v-model="item.customKey1"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="item.customValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndex1)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndex1)"
                  >-</el-button
                >
              </div>

              <div>
                <span>Color：</span>
                <div style="width: 40%; display: inline-block; text-align: left">
                  Visual
                </div>
              </div>
              <div>
                <span>Odor：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">Organoleptic</div> -->
                <el-input
                  v-model="params.testMethodOrdor"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Taste：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">Organoleptic</div> -->
                <el-input
                  v-model="params.testMethodTaste"
                  placeholder="Please Input"
                ></el-input>
              </div>
            </div>
          </div>
        </div>

        <!-- Microbiological Tests -->
        <div
          class="microbiological-tests"
          v-if="
            productTypeVisible.normal || productTypeVisible.microbiologicalShow
          "
        >
          <div>Microbiological Tests</div>
          <div>
            <div class="specification">
              <div>Specification</div>
              <div style="display: flex; align-items: center">
                <span>Total Plate Count：</span>
                <!-- <el-input
                  v-if="params.productType == 'CSFMU' || params.productType == ''"
                  v-model="params.specificationTotalPlateCount"
                  placeholder="Please Input"
                ></el-input>
                <div
                  v-else
                  style="width: 40%;display:inline-block;text-align:left"
                >{{showTotalPlateCount}}</div>-->
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  v-model="params.specificationTotalPlateCount"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Yeast and Mold：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
                  :readonly="specificationYeastAndMoldD"
                -->
                <el-input
                  onkeyup="value=value.replace(/[^\d.]|/g,'')"
                  v-model="params.specificationYeastAndMold"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">cfu / g</div>
                  </template>
                </el-input>
              </div>
              <div>
                <span>E. Coli：</span>
                <el-input
                  v-model="params.specificationEcoli"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Salmonella：</span>
                <el-input
                  v-model="params.specificationSalmonella"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Staph Aureus：</span>
                <el-input
                  v-model="params.specificationStaphAureus"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div style="display: flex; align-items: center">
                <span>Total Coliform：</span>
                <!-- <div style="width: 40%;display:inline-block;text-align:left">&lt;100/g</div>
                -->
                <el-input
                  @blur="checkSpecificationTotalColiforms()"
                  v-model="params.specificationTotalColiforms"
                  placeholder="Please Input"
                >
                  <template slot="prepend">
                    <div style="width: 100%">&lt;</div>
                  </template>
                  <template slot="append">
                    <div style="width: 100%">MPN/g</div>
                  </template>
                </el-input>
              </div>
              <div>
                <el-input
                  v-model="params.microbiologicalCustomerKey1"
                  placeholder="Please Input"
                ></el-input>
                <el-input
                  v-model="params.microbiologicalCustomerValue1"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div
                v-show="params.productType != 'SHXTO'"
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex + 1"
              >
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                ></el-input>
                <el-input
                  v-model="item.customValue"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
            </div>
            <div class="test-method">
              <div>Test Method</div>
              <div>
                <span>Total Plate Count：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->

                <el-input
                  v-model="params.testMethodTotalPlateCount"
                  placeholder="Please Input"
                ></el-input>
              </div>

              <div>
                <span>Yeast and Mold：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="params.testMethodYeastAndMold"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>E. Coli：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="params.testMethodEcoli"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Salmonella：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="params.testMethodSalmonella"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Staph Aureus：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  v-model="params.testMethodTaphAureus"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>Total Coliform：</span>
                <!-- <div style="width: 40%; display: inline-block; text-align: left">
                  FDA-BAM
                </div> -->
                <el-input
                  @blur="checktestMethodTotalColiforms()"
                  v-model="params.testMethodTotalColiforms"
                  placeholder="Please Input"
                ></el-input>
              </div>
              <div>
                <span>{{ params.microbiologicalCustomerKey1 }}：</span>
                <el-input
                  v-model="params.microbiologicalTestMethodCustomerValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  v-show="params.productType != 'SHXTO'"
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button
                >
              </div>
              <div
                v-show="params.productType != 'SHXTO'"
                v-for="(item, micTestsIndex1) in micTestsCustomFieldAttr"
                :key="micTestsIndex1 + 1"
              >
                <span>{{ item.customKey }}：</span>
                <el-input
                  v-model="item.customValue1"
                  placeholder="Please Value Input"
                ></el-input>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex1)"
                  >+</el-button
                >
                <el-button
                  style="margin-top: 14px"
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex1)"
                  >-</el-button
                >
              </div>
            </div>
          </div>
        </div>

        
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <!-- Other Notes -->

        <div class="other-notes" v-if="productTypeVisible.normal">
          <div>Other Notes</div>
        <div>
          <span>Shelf Life：</span>
          <el-select
                v-model="params.shelflifeNumber"
                placeholder="Shelf Life"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in shelflifeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
        </div>
          <div>
            <span>Shelf Life：</span>
            <!-- <span>Three years unopened. Product is sensitive to heat and moisture.</span> -->
            <el-input
              type="textarea"
              :rows="5"
              resize="none"
              style="width: 500px"
              placeholder="Please Input"
              v-model="params.shelflife"
            ></el-input>
          </div>
          <div>
            <span>Storage：</span>
            <el-input
              v-model="params.storage"
              placeholder="Please Input"
            ></el-input>
          </div>

          <p>
            * Color variation from batch to batch of the product may occur due to
            natural variations of raw material.
          </p>
          <p>
            * The pH of powder product should be tested in its 1% aqueous solution
            if necessary.
          </p>
        <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
          <span style="font-weight: 600;">* {{item.content}}</span>
          <el-button type="text" @click="deleteNewNoteTableClick(index)">
            <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
          </el-button>
        </div>
        </div>

        <!-- Liquid EXT OTHER NOTES -->
        <div class="other-notes" v-if="productTypeVisible.liquidExt">
          <div>Handling</div>
        <div>
          <span>Shelf Life：</span>
          <el-select
                v-model="params.shelflifeNumber"
                placeholder="Shelf Life"
                clearable
                filterable
                allow-create
              >
                <el-option
                  v-for="(item, i) in shelflifeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
        </div>
          <div>
            <span>Shelf Life：</span>
            <el-input
              type="textarea"
              :rows="5"
              resize="none"
              style="width: 500px"
              placeholder="Please Input"
              v-model="params.shelflife"
            ></el-input>
          </div>

          <div>
            <span>Storage：</span>
            <el-input
              v-model="params.storage"
              placeholder="Please Input"
            ></el-input>
            <!-- <span>Preserve in tightly sealed containers in a cool area that is protected from light. </span> -->
          </div>
          <p>
            Note: * Color variation from batch to batch of the product may occur
            due to natural variations of raw material.
          </p>
          <p>
            * Some natural sediment may occur upon standing, that does not
            compromise the quality of the product; please warm to ambient
            temperature and mix well before use in such case.
          </p>
          <p>
            * The pH of liquid product should be tested in its 1% aqueous solution
            if the liquid product contains less than 50% water.
          </p>
        <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
          <span style="font-weight: 600;">* {{item.content}}</span>
          <el-button type="text" @click="deleteNewNoteTableClick(index)">
            <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
          </el-button>
        </div>
        </div>
        <!-- Liquid EXT OTHER NOTES END-->

        <!-- EHXTO OTHER ONTES -->
        <div class="other-notes" v-if="productTypeVisible.ehxto || productTypeVisible.shxto">
          <div>Other Notes</div>
        <div>
          <span>Shelf Life：</span>
          <el-select
            v-model="params.shelflifeNumber"
            placeholder="Shelf Life"
            clearable
            filterable
            allow-create
          >
            <el-option
              v-for="(item, i) in shelflifeList"
              :key="i"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <span>Shelf Life：</span>
          <!-- <span> 18 months unopened in the original package. Once opened the oil should be blanketed under nitrogen and used within six months.</span> -->
          <el-input
            type="textarea"
            :rows="5"
            resize="none"
            style="width: 500px"
            placeholder="Please Input"
            v-model="params.shelflife"
          ></el-input>
        </div>
        <div>
          <span>Storage：</span>
          <el-input
            v-model="params.storage"
            placeholder="Please Input"
          ></el-input>
        </div>
        <div>
          <span>Package：</span>
          <el-input
            type="textarea"
            :rows="5"
            resize="none"
            style="width: 500px"
            placeholder="Please Input"
            v-model="params.packageInfo"
          ></el-input>
        </div>
        <p>
          * Color variation from batch to batch of the product may occur due to
          natural variations of raw material.
        </p>
        <p>
          * Some natural sediment may occur upon standing, that does not
          compromise the quality of the product; please warm to ambient
          temperature and mix well before use in such case.
        </p>
        <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
          <span style="font-weight: 600;">* {{item.content}}</span>
          <el-button type="text" @click="deleteNewNoteTableClick(index)">
            <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
          </el-button>
        </div>
        </div>

        <div>
          <el-form :inline="true" class="demo-form-inline" style="color:red;">
              <el-form-item label="">
                Document version in File Library：Rev{{params.libraryRevNumber}}
              </el-form-item>
              <el-form-item label="" style="margin-left:50px;">
                Uploaded Date：{{params.libraryCreateTime}}
              </el-form-item>
          </el-form>
        </div>
        <div>
          <el-form :inline="true" class="demo-form-inline">
            <div v-for="(item, index) in noteTableData" :key="index">
              <el-form-item label="Rev">
                {{index+1}}
              </el-form-item>
              <el-form-item label="RevisionDate">
                <el-input
                  v-model="item.revisionDate"
                  placeholder="RevisionDate"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="RequestedBy">
                <el-input
                  v-model="item.requestedBy"
                  placeholder="RequestedBy"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="Revised Notes">
                <el-input
                  v-model="item.itemsRevised"
                  placeholder="Revised Notes"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="EditedBy">
                <el-input
                  v-model="item.editedBy"
                  placeholder="EditedBy"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="Reason">
                <el-input
                  v-model="item.reason"
                  placeholder="Reason"
                  style="width: 140px"
                  :disabled="item.isComplete"
                ></el-input>
              </el-form-item>
              <el-form-item label="ApprovedBy">
                <el-input
                  v-model="item.approvedBy"
                  placeholder=""
                  style="width: 140px"
                  disabled="disabled"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item style="width: 120px">
                <el-button type="text" @click="addNoteTableClick(index,'en')" 
                  v-if="item.revisionDate && item.requestedBy && item.itemsRevised && item.editedBy && item.reason && item.approvedBy"
                >Complete</el-button> 
                &nbsp;&nbsp;
                <el-button type="text"
                  v-if="!item.isComplete && noteTableData.length>1" @click="removeNoteTableClick(index)">
                  <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
                </el-button> 
              </el-form-item>-->
            </div>
          </el-form>
        </div>

        <!-- EHXTO OTHER ONTES -->
        <!-- buttons -->
        <div>
          <el-checkbox v-model="isSignaturePrint" @change="changeSignatureHandle"
            >Print Please Verify</el-checkbox
          >
        </div>
        <div>
          <el-button v-if="userInfo.operateStatus && formData.verifiedList==0" type="warning" @click="handleNew('Continue Edit',1)">Add to Verify List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Continue Edit')">Save</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('New')">Save & New</el-button>
          <el-button
            :icon="isVerifing ? 'el-icon-loading' : ''"
            v-if="showVerified && pemission(userInfo.username) && userInfo.operateStatus"
            type="warning"
            @click="vertify"
            >Vertify</el-button
          >
          <el-button @click="back">Back Home</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">Print</el-button>
        </div>
      </div>
    </div>
    </div>

    <TPS v-if="!otherType"></TPS>
    <canvas id="myCanvas" width="800" height="500"></canvas>
  </div>
</template>

<script>
import base from "@/common/base.js";
// import printPage from './Print.vue'
import CSFMU from "./PrintProductTypeModule/CSFMU.vue";
import EHXTO from "./PrintProductTypeModule/EHXTO.vue";
import FANDV from "./PrintProductTypeModule/FANDV.vue";
import FMUD from "./PrintProductTypeModule/FMU-D.vue";
import HEXT from "./PrintProductTypeModule/HEXT.vue";
import LiquidEXT from "./PrintProductTypeModule/LiquidEXT.vue";
import OEXT from "./PrintProductTypeModule/OEXT.vue";
import PCGCSFM from "./PrintProductTypeModule/PCG-CSFM.vue";
import PCG from "./PrintProductTypeModule/PCG.vue";
import REXT from "./PrintProductTypeModule/REXT.vue";
import SHXTO from "./PrintProductTypeModule/SHXTO.vue";
// import newItemData from './module/NewItemData'
import newItemMethod from "./module/NewItemMethod";
import TPS from "./TPS";

export default {
  name: "new-item",
  mixins: [base],
  components: {
    CSFMU,
    EHXTO,
    FANDV,
    FMUD,
    HEXT,
    LiquidEXT,
    OEXT,
    PCGCSFM,
    PCG,
    REXT,
    SHXTO,
    TPS,
  },
  data() {
    return {
      otherType: true,
      isSignaturePrint: false,
      productNameCsfmuB: "HC-001 Extract.",
      IsMultiComponent: "Single Component",
      printShow: false,
      productTypeVisible: {
        normal: true,
        shxto: false,
        liquidExt: false,
        ehxto: false,
      },
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason:"",
          approvedBy: "",
          isComplete:false,
        },
      ],
      newNoteArr:[],
      newNote:"",

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,
      commonNameArrIndex: -1,

      preservativeArr: [],
      preservativeInputVisible: "",
      preservativeInputValue: "",
      preservativeArrIndex: -1,

      majorConstituentsInNativeHerbsArr: [],
      majorConstituentsInNativeHerbsInputVisible: "",
      majorConstituentsInNativeHerbsInputValue: "",
      majorConstituentsInNativeHerbsArrIndex: -1,

      processingArr: [],
      processingInputVisible: "",
      processingInputValue: "",
      processingArrIndex: -1,

      ingredientsArr: [],
      inputVisible: "",
      inputValue: "",
      ingredientsArrIndex: -1,

      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inclNameArrIndex: -1,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameArrIndex: -1,

      commonOtherNameArr: [],
      commonOtherNameInputVisible: "",
      commonOtherNameInputValue: "",
      commonOtherNameArrIndex: -1,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedArrIndex: -1,

      percentageArr: [],
      percentageInputVisible: "",
      percentageInputValue: "",
      percentageArrIndex: -1,

      titleType: "Add",
      sWidth: 0,
      newItemType: "",
      // CSFMU、F&V、FMU-D、HEXT、PCG、REXT
      // params.productType: "",
      productTypeList: [
        { label: "CSFMU-S", value: "CSFMU-S" },
        { label: "CSFMU-B", value: "CSFMU-B" },
        { label: "F&V", value: "F&V" },
        { label: "FMU-D", value: "FMU-D" },
        { label: "HEXT", value: "HEXT" },
        { label: "PCG", value: "PCG" },
        { label: "REXT", value: "REXT" },
        { label: "OEXT", value: "OEXT" },
        { label: "PCG-CSFMU-S", value: "PCG-CSFMU-S" },
        { label: "PCG-CSFMU-B", value: "PCG-CSFMU-B" },
        { label: "SHXTO", value: "SHXTO" },
        { label: "EHXTO", value: "EHXTO" },
        { label: "Liquid EXT-S", value: "Liquid EXT-S" },
        { label: "Liquid EXT-B", value: "Liquid EXT-B" },
        { label: "SDNP", value: "SDNP" },
        { label: "SDNP-L", value: "SDNP-L" },
      ],
      // Powder, Granular
      appearance: "",
      appearanceList: [
        { label: "Powder", value: "Powder" },
        { label: "Granular", value: "Granular" },
      ],
      //  None; Maltodextrin
      carrier: "",
      carrierList: [
        { label: "None", value: "None" },
        { label: "Maltodextrin", value: "Maltodextrin" },
      ],

      carrierSolvent: "",
      carrierSolventList: [
        { label: "Glycerin", value: "Glycerin" },
        { label: "Butylene glycol", value: "Butylene glycol" },
        { label: "Propylene glycol", value: "Propylene glycol" },
      ],
      // 80-100 Mesh、 20 Mesh、 10 Mesh
      meshSize: "",
      meshSizeList: [
        { label: "80-100 Mesh", value: "80-100 Mesh" },
        { label: "20 Mesh", value: "20 Mesh" },
        { label: "10 Mesh", value: "10 Mesh" },
        { label: ">95% pass 80 Mesh", value: ">95% pass 80 Mesh" },
      ],
      colorList: [],
      colorPowder: [
        { label: "White to off-white", value: "White to off-white" },
        {
          label: "Off-white to light yellow",
          value: "Off-white to light yellow",
        },
        { label: "White to gray", value: "White to gray" },
        { label: "Light yellow to yellow", value: "Light yellow to yellow" },
        {
          label: "Yellow to reddish orange",
          value: "Yellow to reddish orange",
        },
        {
          label: "Light yellow to yellowish brown",
          value: "Light yellow to yellowish brown",
        },
        {
          label: "Light yellowish brown to dark yellowish brown",
          value: "Light yellowish brown to dark yellowish brown",
        },
        {
          label: "Light brownish yellow to dark brownish yellow",
          value: "Light brownish yellow to dark brownish yellow",
        },
        {
          label: "Light brown to dark brown",
          value: "Light brown to dark brown",
        },
        { label: "Light pink to pink", value: "Light pink to pink" },
        {
          label: "Light green to yellowish green",
          value: "Light green to yellowish green",
        },
        {
          label: "Light brownish red to dark brownish red",
          value: "Light brownish red to dark brownish red",
        },
        {
          label: "Light reddish brown to dark reddish brown",
          value: "Light reddish brown to dark reddish brown",
        },
        {
          label: "Light purple to dark purple",
          value: "Light purple to dark purple",
        },
        {
          label: "Purplish red to purplish black",
          value: "Purplish red to purplish black",
        },
        { label: "Black", value: "Black" },
      ],
      colorLiquid: [
        { label: "Clear", value: "Clear" },
        { label: "White to gray", value: "White to gray" },
        { label: "Off white to yellow", value: "Off white to yellow" },
        { label: "Light yellow to yellow", value: "Light yellow to yellow" },
        {
          label: "Light amber brown to amber brown",
          value: "Light amber brown to amber brown",
        },
        {
          label: "Light brown to dark brown",
          value: "Light brown to dark brown",
        },
        {
          label: "Light brownish yellow to dark brownish yellow",
          value: "Light brownish yellow to dark brownish yellow",
        },
        {
          label: "Light yellowish brown to dark yellowish brown",
          value: "Light yellowish brown to dark yellowish brown",
        },
        { label: "Brownish to tan", value: "Brownish to tan" },
        {
          label: "Reddish brown to dark red brown",
          value: "Reddish brown to dark red brown",
        },
        {
          label: "Light green to greenish yellow",
          value: "Light green to greenish yellow",
        },
        {
          label: "Light red brown to dark red brown",
          value: "Light red brown to dark red brown",
        },
        {
          label: "Purplish to dark purplish",
          value: "Purplish to dark purplish",
        },
        {
          label: "Purplish red to purplish black",
          value: "Purplish red to purplish black",
        },
      ],
      colorOil: [
        { label: "Light yellow to yellow", value: "Light yellow to yellow" },
        {
          label: "Light yellow to brownisn yellow",
          value: "Light yellow to brownisn yellow",
        },
        {
          label: "Light reddish brown to dark brownish red",
          value: "Light reddish brown to dark brownish red",
        },
        { label: "Red", value: "Red" },
      ],
      physicalPropertiesList: [
        { label: "Blend", value: "Blend" },
        { label: "Single", value: "Single" },
        { label: "Granulate", value: "Granulate" },
        { label: "Liquid", value: "Liquid" },
        { label: "Oil", value: "Oil" },
        { label: "Essential oil", value: "Essential oil" },
        { label: "Glycerin", value: "Glycerin" },
      ],
      revDateTime:base.methods.getLocalNowTime(),
      params: {
        baseInfoCustomField:"",
        libraryRevNumber:0,
        libraryCreateTime:'',
        versionNumber:"",
        revNumber:0,
        revDate:[],
        documentNotes:"",
        noteBy:"",
        storage: "",
        percentage: "",
        liquidExtCustomerKey1: "",
        liquidExtCustomerValue1: "",
        liquidExtCustomerKey2: "",
        liquidExtCustomerValue2: "",
        liquidExtCustomerKey3: "",
        liquidExtCustomerValue3: "",
        liquidExtCustomerKey4: "",
        liquidExtCustomerValue4: "",
        liquidExtCustomerKey5: "",
        liquidExtCustomerValue5: "",

        liquidExtCustomerValue3TM: "",
        liquidExtCustomerValue4TM: "",
        liquidExtCustomerValue5TM: "",
        ehxtoIcip: "",
        ehxtoHomogeneity: "",
        ehxtoExtractionProcess:
          "Controlled-temperature water extraction process, spray dried",
        ehxtoSolventExtraction: "",
        ehxtoCarrier: "",
        ehxtoPesticides: "",
        ehxtoCertification: "",
        ehxtoCustomerKey1: "",
        ehxtoCustomerValue1: "",
        ehxtoTestLimitCustomerKey1: "",
        ehxtoTestLimitCustomerValue1: "",
        ehxtoTestLimitCustomerKey2: "",
        ehxtoTestLimitCustomerValue2: "",
        ehxtoTestLimitCustomerKey3: "",
        ehxtoTestLimitCustomerValue3: "",
        ehxtoTestLimitCustomerKey4: "",
        ehxtoTestLimitCustomerValue4: "",
        ehxtoTestMethodCustomerKey1: "",
        ehxtoTestMethodCustomerValue1: "",
        ehxtoTestMethodCustomerKey2: "",
        ehxtoTestMethodCustomerValue2: "",
        ehxtoTestMethodCustomerKey3: "",
        ehxtoTestMethodCustomerValue3: "",
        ehxtoTestMethodCustomerKey4: "",
        ehxtoTestMethodCustomerValue4: "",
        productSubType: "",
        // 新增字段
        otherNotesRevision: "",

        microbiologicalCustomerKey1: "",
        microbiologicalCustomerValue1: "",
        microbiologicalTestMethodCustomerKey1: "",
        microbiologicalTestMethodCustomerValue1: "",

        specificationCustomerKey1: "",
        specificationCustomerValue1: "",
        testMethodCustomerKey1: "",
        testMethodCustomerValue1: "",

        specificationCustomerKey2: "",
        specificationCustomerValue2: "",
        testMethodCustomerKey2: "",
        testMethodCustomerValue2: "",

        specificationCustomerKey3: "",
        specificationCustomerValue3: "",
        testMethodCustomerKey3: "",
        testMethodCustomerValue3: "",

        specificationCustomerKey4: "",
        specificationCustomerValue4: "",
        testMethodCustomerKey4: "",
        testMethodCustomerValue4: "",

        specificationCustomerKey5: "",
        specificationCustomerValue5: "",
        testMethodCustomerKey5: "",
        testMethodCustomerValue5: "",

        generalSpecificationKey1: "",
        generalSpecificationValue1: "",
        generalSpecificationKey2: "",
        generalSpecificationValue2: "",
        generalSpecificationKey3: "",
        generalSpecificationValue3: "",
        productId: "",
        processingAid: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
        customKey3: "",
        customValue3: "",
        processing: "",
        countryOfOrigin: "",
        color: "Compares to standard",
        odor: "",
        taste: "",
        testLimitMoisture: "",
        testMethodMoisture: "",
        testLimitSpecificaGravity: "",
        testMethodSpecificaGravity: "",
        testLimitRefractiveIndex: "",
        testMethodRefractiveIndex: "",
        testLimitOpticalRotation: "",
        testMethodOpticalRotation: "",
        testLimitArsenic: "",
        testMethodArsenic: "",
        testLimitCadmium: "",
        testMethodCadmium: "",
        testLimitLead: "",
        testMethodLead: "",
        testLimitMercury: "",
        testMethodMercury: "",
        testLimitAcidValue: "",
        testMethodAcidValue: "",
        testLimitPeroxideValue: "",
        testMethodPeroxideValue: "",
        testLimitSaponificationValue: "",
        testMethodSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testMethodUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testMethodMoistureVolatiles: "",
        testLimitPesticides: "",
        testMethodPesticides: "",
        testLimitThcResidue: "",
        testMethodThcResidue: "",

        fattryLimitCaprylic: "",
        fattryMethodCaprylic: "",
        fattryLimitCapric: "",
        fattryMethodCapric: "",

        fattryLimitLauric: "",
        fattryMethodLauric: "",
        fattryLimitMyristic: "",
        fattryMethodMyristic: "",
        fattryLimitMyristoleic: "",
        fattryMethodMyristoleic: "",
        fattryLimitArachidic: "",
        fattryMethodArachidic: "",
        fattryLimitArachidonic: "",
        fattryMethodArachidonic: "",
        fattryLimitEicosapentaenoic: "",
        fattryMethodEicosapentaenoic: "",
        fattryLimitDocosahexaenoic: "",
        fattryMethodDocosahexaenoic: "",

        fattryLimitLinoleicacid: "",
        fattryMethodLinoleicacid: "",
        fattryLimitPunicicacid: "",
        fattryMethodPunicicacid: "",

        fattryLimitPalmitica: "",
        fattryMethodPalmitica: "",
        fattryLimitPalmitoleic: "",
        fattryMethodPalmitoleic: "",
        fattryLimitStearic: "",
        fattryMethodStearic: "",
        fattryLimitOleicVaccenic: "",
        fattryMethodOleicVaccenic: "",
        fattryLimitLinoleic: "",
        fattryMethodLinoleic: "",
        fattryLimitALinolenic: "",
        fattryMethodALinolenic: "",
        testLimitCustomKey1: "",
        testLimitCustomKey1Value: "",
        testMethodCustomKey1Value: "",
        testLimitCustomKey2: "",
        testLimitCustomKey2Value: "",
        testMethodCustomKey2Value: "",
        fattryLimitKey1: "",
        fattryLimitKey1Value: "",
        fattryMethodKey1Value: "",
        fattryLimitKey2: "",
        fattryLimitKey2Value: "",
        fattryMethodKey2Value: "",
        fattryLimitKey3: "",
        fattryLimitKey3Value: "",
        fattryMethodKey3Value: "",
        fattryLimitKey4: "",
        fattryLimitKey4Value: "",
        fattryMethodKey4Value: "",
        fattryLimitKey5: "",
        fattryLimitKey5Value: "",
        fattryMethodKey5Value: "",
        fattryLimitC8: "",
        fattryMethodC8: "",
        fattryLimitC10: "",
        fattryMethodC10: "",
        fatterLimitOthers: "",
        fatterMethodOthers: "",
        packageInfo: "",
        productDefinition: "",
        carrierSolvent: "",
        preservative: "",
        botanicalExtractionProcess: "",
        botanicalSolventForExtraction: "",
        analysisRangeBioactivesLevel: "",
        analysisTestBioactivesLevel: "",
        analysisRangePh: "",
        analysisRangeHeavyMetal: "Conforms to USP <231>",
        analysisTestPh: "",
        analysisRangePesticide: "Conforms to USP<565>",
        analysisTestPesticide: "",
        analysisRangeTotalPlateCount: "100",
        analysisTestTotalPlateCount: "",
        analysisRangeYeastMold: "100",
        analysisMethodPh: "",
        analysisMethodHeavyMetal: "",
        analysisMethodPesticide: "",
        analysisMethodTotalPlateCount: "100",
        analysisMethodYeastMold: "100",
        majorConstituentsInNativeHerbs: "",
        testLimitHeavyMetals: "10",

        analysisArsenic: "1",
        analysisCadmium: "1",
        analysisLead: "1",
        analysisMercury: "1",

        analysisMethodArsenic: "USP<232>",
        analysisMethodCadmium: "USP<232>",
        analysisMethodLead: "USP<232>",
        analysisMethodMercury: "USP<232>",

        productName: "",
        itemCode: "",
        productDescription: "",
        generalSpecificatio: "",
        createTime: "",
        productType: "",
        ingredients: "",
        productGrade: "",
        inciName: "",
        botanicalName: "",
        commonOtherName: "",
        partUsed: "",
        extractionRatio: "",
        importantConstitueInPlant: "",
        appearance: "",
        solubility: "Water soluble/dispersible",
        homogeneity: "",
        extractionProcess: "",
        solventForExtracti: "Water",
        carrier: "",
        pesticides: "",
        certification: "Kosher certified, HALAL certified",
        specificationStandard1: "",
        specificationStandard2: "",
        specificationStandard3: "",
        specificationMeshSize: "",
        specificationLossonDrying: "6",
        specificationBulkDensity: "0.25-0.75",
        specificationArsenic: "",
        specificationCadmium: "",
        specificationLead: "",
        specificationMercury: "",
        specificationHptlcIdentification: "",
        specificationTotalHeavyMetals: "10",
        specificationColor: "Compares to standard",
        specificationOrdor: "Compares to standard",
        specificationTaste: "Compares to standard",
        testMethodStandard1: "DracoUV-",
        testMethodStandard2: "DracoHPLC-",
        testMethodStandard3: "",
        testMethodMeshSize: "",
        testMethodLossOnDrying: "",
        testMethodBulkDensity: "",
        testMethodHptlcIdentification: "",
        testMethodHeavyMetals: "10",
        testMethodColor: "",
        testMethodOrdor: "Organoleptic",
        testMethodTaste: "Organoleptic",
        testMethodTotalColiforms: "FDA-BAM",
        specificationTotalPlateCount: "",
        specificationYeastAndMold: "100",
        specificationEcoli: "",
        specificationSalmonella: "",
        specificationStaphAureus: "",
        specificationTotalColiforms: "",
        testMethodTotalPlateCount: "FDA-BAM",
        testMethodYeastAndMold: "FDA-BAM",
        testMethodEcoli: "FDA-BAM",
        testMethodSalmonella: "FDA-BAM",
        testMethodTaphAureus: "FDA-BAM",
        shelflife: "",        
        shelflifeNumber:"",
        stotage: "",
        fattyAcidComposition: "",
        shTest: "",
        confidential: false,
        physicalProperties: "",
        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",

        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
        tableInputCustomField: "",
        customFormula:false,
        customer:""
      },
      shelflifeList:[
        { label: "12 months", value: "12" },
        { label: "18 months", value: "18" },
        { label: "24 months", value: "24" },
        { label: "36 months", value: "36" },
      ],
      itemCodeExist: 1,
      loading: false,
      // 对应禁止输入
      inciNameD: false,
      ingredientsD: false,
      botanicalNameD: false,
      commonOtherNameD: false,
      partUsedD: false,
      isSubmit: false,
      showVerified: false,
      // verifiedRes: 1,
      isVerifing: false,
      checkItemCode: false,
      checkProductName: false,
      checkProductType: false,
      specificationYeastAndMoldD: false,
      hasVerifyItemCode: false,
      // 输入框禁用
      colorD: false,
      fattyAcidCompositionArr: [],
      shTestArr: [],
      shTest: {
        testLimitAcidValue: "",
        testLimitPeroxideValue: "",
        testLimitSaponificationValue: "",
        testLimitUnsaponifiableMaster: "",
        testLimitMoistureVolatiles: "",
        testLimitHeavyMetals: "",
        testLimitLead: "",
        testLimitArsenic: "",
        testLimitCadmium: "",
        testLimitMercury: "",
        testLimitPesticides: "USP <561>",
        testMethodAcidValue: "AOCS Cd 3d-63",
        testMethodPeroxideValue: "AOCS Cd 8b-90",
        testMethodSaponificationValue: "AOCS Cd 3-25",
        testMethodUnsaponifiableMaster: "AOCS Ca 6a-40",
        testMethodMoistureVolatiles: "AOCS Ca 2d-25",
        testMethodLead: "AOCS Ca 18c-91",
        testMethodArsenic: "AOAC 986.15",
        testMethodCadmium: "AOAC 986.15",
        testMethodMercury: "AOAC 971.21",
        testMethodPesticides: "USP <561>",
        testCustomFieldAttr: [],
      },
      fattyAcidComposition: {
        fattryLimitPalmitica: "", //28.0-35.0
        fattryLimitPalmitoleic: "", //25.0-55.0
        fattryLimitStearic: "", //0-3.0
        fattryLimitOleicVaccenic: "", //21.0-32.0
        fattryLimitLinoleic: "", //3.0-12.0
        fattryLimitALinolenic: "", //0-4.0
        fatterLimitOthers: "", //0-3.0
        fattryMethodPalmitica: "AOCS Ce 1e-91",
        fattryMethodPalmitoleic: "AOCS Ce 1e-91",
        fattryMethodStearic: "AOCS Ce 1e-91",
        fattryMethodOleicVaccenic: "AOCS Ce 1e-91",
        fattryMethodLinoleic: "AOCS Ce 1e-91",
        fattryMethodALinolenic: "AOCS Ce 1e-91",
        fatterMethodOthers: "AOCS Ce 1e-91",
        fattryLimitCaprylic: "",
        fattryMethodCaprylic: "AOCS Ce 1e-91",
        fattryLimitCapric: "",
        fattryMethodCapric: "AOCS Ce 1e-91",
        fattryLimitLinoleicacid: "",
        fattryMethodLinoleicacid: "AOCS Ce 1e-91",
        fattryLimitPunicicacid: "",
        fattryMethodPunicicacid: "AOCS Ce 1e-91",

        fattryLimitLauric: "",
        fattryMethodLauric: "AOCS Ce 1e-91",
        fattryLimitMyristic: "",
        fattryMethodMyristic: "AOCS Ce 1e-91",
        fattryLimitMyristoleic: "",
        fattryMethodMyristoleic: "AOCS Ce 1e-91",
        fattryLimitArachidic: "",
        fattryMethodArachidic: "AOCS Ce 1e-91",
        fattryLimitArachidonic: "",
        fattryMethodArachidonic: "AOCS Ce 1e-91",
        fattryLimitEicosapentaenoic: "",
        fattryMethodEicosapentaenoic: "AOCS Ce 1e-91",
        fattryLimitDocosahexaenoic: "",
        fattryMethodDocosahexaenoic: "AOCS Ce 1e-91",
        fattyCustomFieldAttr: [],
      },
      imageUrl: "",
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      micTestsCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        productDescription: "",
        generalSpecificatio: "",
        productType: "",
        productSubType: "",
        ingredients: "",
        inciName: "",
        botanicalName: "",
        commonName: "",
        commonOtherName: "",
        partUsed: "",
        appearance: "",
        verified: "",
        verifiedName: "",
        verifiedTime: "",
        createTime: "",
        updateTime: "",
        createName: "",
        editName: "",
        jsonValue: "",
        verifiedList:0,
      },
      isShowAlonePrint:false,
      isShowUpdate:false,
      specUserInfo:{},
      BaseInfoCustomFieldAttr: [{
        baseInfoInput1: "",
        baseInfoInput2: ""
      }],
      baseInfoField: {
        baseInfoInput1: "",
        baseInfoInput2: ""
      },
    };
  },
  computed: {
    showTotalPlateCount() {
      // 选择F&V：<5000/g； FMU-D、HEXT、 REXT：<3000/g ；PCG：<100/
      switch (this.params.productType) {
        case "F&V":
          return "<5000/g";
        case "FMU-D":
        case "HEXT":
        case "REXT":
        case "OEXT":
          return "<3000/g";
        case "PCG":
          return "<100/g";
        default:
          return "";
      }
    },
    verify_show: {
      get() {
        switch (this.params.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
      set() {
        switch (this.params.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
    },
    // verify_show() {
    //   switch (this.verifiedRes) {
    //     case 2:
    //       return { icon: "el-icon-success", color: "#67c23a" };
    //     case 0:
    //       return { icon: "el-icon-error", color: "#f56c6c" };
    //     default:
    //       return { icon: "el-icon-question", color: "#a6a9ad" };
    //   }
    // }
  },
  watch: {
    params: {
      // handler(get, set) {
      //   if (get.productType == "REXT") {
      //     set.extractionRatio = "4:1";
      //   }
      handler() {
        this.specificationYeastAndMoldD = false;
        console.log(this.params.productType);
        switch (this.params.productType) {
          case "FMU-D":
            (this.inciNameD = true),
              // (this.botanicalNameD = true),
              // (this.commonOtherNameD = true),
              // (this.partUsedD = true);
              // (this.params.inciName = "");
              // this.params.botanicalName = "";
              // this.params.commonOtherName = "";
              // this.params.partUsed = "";
              //this.inclNameArr = [];
              // this.botanicalNameArr = [];
              //this.partUsedNameArr = [];
              // this.commonOtherNameArr = [];
              (this.ingredientsD = false);
            break;
          case "CSFMU-B":
          case "SDNP":
          case "CSFMU-S":
            {
              (this.inciNameD = true),
                (this.botanicalNameD = false),
                (this.commonOtherNameD = false),
                (this.partUsedD = false);
              // this.params.inciName = "";
              // this.inclNameArr = [];
              this.ingredientsD = false;
            }
            break;
          case "F&V":
          case "HEXT":
          case "REXT":
          case "OEXT":
            {
              (this.ingredientsD = true),
                (this.inciNameD = true),
                (this.botanicalNameD = false),
                (this.commonOtherNameD = false),
                (this.partUsedD = false),
                //(this.params.inciName = "");
                //this.params.ingredients = "";
                //this.ingredientsArr = [];
                (this.inclNameArr = []);
            }
            break;
          case "PCG":
            (this.inciNameD = false),
              (this.botanicalNameD = false),
              (this.commonOtherNameD = false),
              (this.partUsedD = false);
            this.specificationYeastAndMoldD = true;
            this.ingredientsD = false;
            break;
          case "PCG-CSFMU-B":
          case "PCG-CSFMU-S":
            (this.inciNameD = false),
              // (this.botanicalNameD = false),
              // (this.commonOtherNameD = true),
              // (this.partUsedD = false);
              (this.specificationYeastAndMoldD = true);
            this.ingredientsD = false;
            // this.params.commonOtherName = "";
            // this.commonOtherNameArr = [];
            break;
          default:
            (this.inciNameD = false),
              (this.botanicalNameD = false),
              (this.commonOtherNameD = false),
              (this.partUsedD = false),
              (this.ingredientsD = false);
            break;
        }
        this.moduleVisibleCon(this.params.productType);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: newItemMethod,
  mounted() {
    this.titleType = "Add";
    let params = { ...this.$route.query };
    if (params.type) {
      this.otherType = false;
    }
    if (params.id) {
      if(params.aloneprint){
        this.isShowAlonePrint = params.aloneprint == "1" ? true : false
      }
      if(params.showUpdate){
        this.isShowUpdate = params.showUpdate == "1" ? true : false
      }
      this.titleType = "Edit";
      this.itemCodeExist == 2;
      this.getData(params);
      this.newItemType = "Edit";
      this.showVerified = true;
    } else {
      this.newItemType = "New";
    }
    // this.sWidth = window.screen.width + "px";
    this.sWidth = "1920px";

    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.font = "60px Arial";
    ctx.fillStyle = "#C7C7C7";
    ctx.fillText("Confidential", 300, 300, 400, 100);
    var image = c.toDataURL("image/png");
    //console.log(image);
    // var pageDiv=document.getElementById("pageDiv");
    // pageDiv.style.backgroundImage="url("+image+")";
    c.style.display = "none";
    this.imageUrl = image;
    // console.log(pageDiv.style.width);
    // c.style.width="1000px";
    // c.style.height="500px";
  },
  created() {
    let specUserInfo = JSON.parse(localStorage.getItem('specUserInfo'))
    if(specUserInfo){
      this.specUserInfo = specUserInfo;
      this.params.noteBy = this.specUserInfo.username + " on "+ base.methods.formatDate({date:base.methods.getLocalNowTime(),formatStr:"yyyy-MM-dd HH:mm"})
    }
    
  },
};
</script>
<style lang="scss" scoped>
@import "./module/NewItemStyle.scss";
.acidSpanClass {
  width: 30% !important;
}
</style>

