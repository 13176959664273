<template>
  <div class="page">
    <center>
      <div style="width: 80vw">
        <!-- Base Info -->
        <div class="baseInfo">
          <h2 class="row-title">Base Info</h2>
          <div style="clear: both"></div>
          <el-form
            ref="form"
            :model="form"
            label-width="180px"
            style="clear: both; margin-top: 20px; width: 30vw"
          >
            <el-form-item label="Product Type">
              <el-select
                @change="productTypeChange"
                v-model="form.ProductType"
                placeholder="Please Select Your ProductType"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, i) in form.ProductTypeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Draco Product Name">
              <el-input
                v-model="form.DracoProductName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Item Code">
              <el-input
                v-model="form.ItemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Draco Item Code">
              <el-input
                v-model="form.DracoItemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Custom Row">
              <el-input
                v-model="form.BaseInfoCustomRowKey1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.BaseInfoCustomRowValue1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.BaseInfoCustomRowKey2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.BaseInfoCustomRowValue2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- Supplier Information -->
        <div class="supplierInfomation">
          <h2 class="row-title">Supplier Information</h2>
          <div style="clear: both"></div>
          <el-form
            ref="form"
            :model="form"
            label-width="180px"
            style="clear: both; margin-top: 20px; width: 30vw"
          >
            <el-form-item label="Supplier Product Name">
              <el-input
                v-model="form.SupplierProductName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Supplier">
              <el-input
                v-model="form.Supplier"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Supplier Item Code">
              <el-input
                v-model="form.SupplierItemCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Country of Origin">
              <el-input
                v-model="form.CountryOfOrigin"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.SiCustomRowKey1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.SiCustomRowValue1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.SiCustomRowKey2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.SiCustomRowValue2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- Supplier Lot/Batch No. -->
        <div class="supplier-lot-batch-no">
          <h2 class="row-title">Supplier Lot/Batch No.</h2>
          <div style="clear: both"></div>
          <el-form
            ref="form"
            :model="form"
            label-width="180px"
            style="clear: both; margin-top: 20px; width: 30vw"
          >
            <el-form-item label="Supplier Lot/Batch No.">
              <el-input
                v-model="form.SupplierLotBatchNo"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Draco Lot#">
              <el-input
                v-model="form.DracoLot"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Invoice">
              <el-input
                v-model="form.Invoice"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Quantities">
              <el-input
                v-model="form.Quantities"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Receiving Date">
              <el-input
                v-model="form.ReceivingDate"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Purchasing No.">
              <el-input
                v-model="form.PurchasingNo"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Packaging">
              <el-input
                v-model="form.Packaging"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.SupplierLotCustomRowKey1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.SupplierLotCustomRowValue1"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.SupplierLotCustomRowKey2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.SupplierLotCustomRowValue2"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-input
                v-model="form.SupplierLotCustomRowKey3"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
              <el-input
                v-model="form.SupplierLotCustomRowValue3"
                class="custom-row"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- Attachments -->
        <div class="supplier-lot-batch-no">
          ·
          <h2 class="row-title">Attachments.</h2>
          <div style="clear: both"></div>
          <el-form
            ref="form"
            :model="form"
            label-width="180px"
            style="clear: both; margin-top: 20px; width: 30vw"
          >
            <el-form-item label="Supplier TECH DOC. EDITOR">
              <el-upload
                class="upload-demo"
                ref="uploadSupplierSpecFileList"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.SupplierSpecFileList"
                :auto-upload="false"
                :show-file-list="true"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="Supplier COA">
              <el-upload
                class="upload-demo"
                ref="uploadSupplierCoaFileList"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.SupplierCoaFileList"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="Supplier SDS">
              <el-upload
                class="upload-demo"
                ref="uploadSupplierSdsFileList"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.SupplierSdsFileList"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="Others Attachments">
              <el-upload
                class="upload-demo"
                ref="uploadOthersAttachmentsFileList"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.OthersAttachmentsFileList"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>

            <el-form-item label="CustomRow">
              <el-upload
                class="upload-demo"
                ref="uploadCustomRowFileList1"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.CustomRowFileList1"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-upload
                class="upload-demo"
                ref="uploadCustomRowFileList2"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.CustomRowFileList2"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item label="CustomRow">
              <el-upload
                class="upload-demo"
                ref="uploadCustomRowFileList3"
                multiple
                action
                :http-request="uploadFile"
                :on-remove="handleRemove"
                :file-list="fileModel.CustomRowFileList3"
                :auto-upload="false"
                :on-preview="handlePreview"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
        </div>

        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" :disabled="isDisabled" type="primary" @click="handleNew('ContinueEdit')">Save</el-button>
          <el-button v-if="userInfo.operateStatus" :disabled="isDisabled" type="primary" @click="handleNew('Save&New')">Save & New</el-button>
          <el-button @click="back">Back Home</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">Print</el-button>
        </div>
      </div>
    </center>
  </div>
</template>
<script>
import base from "@/common/base.js";
export default {
  mixins: [base],
  props: ["typeChangeName"],
  data() {
    return {
      form: {
        FileNames: [{ key: "", names: [], namesKey:[] }],
        ProductType: {},
        ProductTypeList: [{ label: "TPS", value: "TPS" }],
        DracoProductName: "",
        productName:"",
        ItemCode: "",
        itemCode:"",
        DracoItemCode: "",
        BaseInfoCustomRowKey1: "",
        BaseInfoCustomRowValue1: "",
        BaseInfoCustomRowKey2: "",
        BaseInfoCustomRowValue2: "",
        SupplierProductName: "",
        Supplier: "",
        SupplierItemCode: "",
        CountryOfOrigin: "",
        SiCustomRowKey1: "",
        SiCustomRowValue1: "",
        SiCustomRowKey2: "",
        SiCustomRowValue2: "",
        SupplierLotBatchNo: "",
        DracoLot: "",
        Invoice: "",
        Quantities: "",
        ReceivingDate: "",
        PurchasingNo: "",
        Packaging: "",
        SupplierLotCustomRowKey1: "",
        SupplierLotCustomRowValue1: "",
        SupplierLotCustomRowKey2: "",
        SupplierLotCustomRowValue2: "",
        SupplierLotCustomRowKey3: "",
        SupplierLotCustomRowValue3: "",
      },
      fileModel: {
        SupplierSpecFileList: [],
        SupplierCoaFileList: [],
        SupplierSdsFileList: [],
        OthersAttachmentsFileList: [],
        CustomRowFileList1: [],
        CustomRowFileList2: [],
        CustomRowFileList3: [],
      },
      formData: {},
      isDisabled:false,
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    handleRemove() {},
    productTypeChange() {
      let flag = true;
      if (this.form.ProductType == "TPS") {
        flag = false;
      }
      this.$emit("tpsTypeChange", flag);
    },
    back() {
      this.$router.go(-1);
    },
    uploadFile(param) {
      console.info(param.file);
    },
    handleNew(type) {
      this.isDisabled = true
      this.formData = new FormData();
      // 将上传的文件放进formData对象
      this.form.FileNames = [];
      let params = { ...this.$route.query };
      this.form.productName = this.form.DracoProductName
      this.form.itemCode = this.form.ItemCode
      this.appendFormDate(this.$refs.uploadSupplierSpecFileList,"supplierSpec");
      this.appendFormDate(this.$refs.uploadSupplierCoaFileList, "supplierCoa");
      this.appendFormDate(this.$refs.uploadSupplierSdsFileList, "supplierSds");
      this.appendFormDate(this.$refs.uploadOthersAttachmentsFileList,"othersAttachments");
      this.appendFormDate(this.$refs.uploadCustomRowFileList1, "customRow1");
      this.appendFormDate(this.$refs.uploadCustomRowFileList2, "customRow2");
      this.appendFormDate(this.$refs.uploadCustomRowFileList3, "customRow3");

      let jsonStr = JSON.stringify(this.form);

      this.formData.append("productName", this.form.productName);
      this.formData.append("itemCode", this.form.itemCode);
      this.formData.append("jsonValue", jsonStr);
      if (params.id) {
        this.formData.append("id", params.id);
      } else {
        this.formData.append("id", 0);
      }
      console.info('this.formData:',this.formData);
      console.log(type)
      this.$api.handleTps
        .tpsUploadSave(this.formData)
        .then(() => {
          this.$message({
            type: "success",
            message: "SuccessFul",
          });
          let params = { ...this.$route.query };
          if (params.id) {
            // this.$router.push({
            //   path: "new-item",
            //   query: { id: params.id, type: "TPS" },
            // });
            this.reloadPage(type);
          } 
          else {
            // this.$router.push({
            //   path: "new-item",
            //   query: { type: "TPS" },
            // });
            this.reloadPage(type);
          }
          this.isDisabled = false
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
          });
          console.log(err);
          this.isDisabled = false
        });
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "new-item",
          query: { type: "TPS" },
        });
        this.$router.go(0);
      }
    },
    appendFormDate(list, key) {
      let item = { key: key, names: [], namesKey:[] };
      list.uploadFiles.forEach((file) => {
        console.log('file')
        console.log(file)
        let fileType=file.name.substring(file.name.indexOf('.')+1).toLowerCase()
        if (file.raw) {
          this.formData.append(key + "Files", file.raw);
          item.namesKey.push(file.uid+"."+fileType)
        }
        else{
          item.namesKey.push(file.nameKey);
        }
        item.names.push(file.name);
      });
      this.form.FileNames.push(item);
    },
    printPage() {},
    handlePreview(file) {
      console.log("预览文件");
      console.log(file);
      var a = document.createElement("a");
      a.href = file.url;
      a.target = "_blank";
      a.click();
      // if (window.navigator.msSaveOrOpenBlob) {
      //   navigator.msSaveBlob(file.url, file.name);
      // } else {
      //   // var link = document.createElement('a');
      //   // link.download = file.name;  
      //   // link.href = window.URL.createObjectURL(file.url);
      //   // link.click();
      //   // window.URL.revokeObjectURL(link.href);
      //   var a = document.createElement("a");
      //   a.download =file.name;
      //   a.href = file.url;
      //   a.target=file.url;
      //   a.click();  
      // }
    },
    getDetailById(params) {
      this.$api.handleTps.getTpsDetail(params).then((data) => {
        // this.formModel = data;
        // this.formModel.rowData = JSON.parse(data.rowData);
        
        
        if (data.jsonValue) {
          let rowData = JSON.parse(data.jsonValue);
          Object.keys(rowData).forEach((key) => {
            this.form[key] = rowData[key];
          });
          console.log("======文件=======");
          console.log(this.$api.getFileUrl());
          console.log(rowData);
          console.log(rowData.FileNames);
          if(rowData.FileNames[0].names){
            let i0=0;
            rowData.FileNames[0].names.forEach((element) => {
              let nameK=rowData.FileNames[0].namesKey[i0]
              this.fileModel.SupplierSpecFileList.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i0++
            });
          }
          if(rowData.FileNames[1].names){
            let i1=0;
            rowData.FileNames[1].names.forEach((element) => {
              let nameK=rowData.FileNames[1].namesKey[i1]
              this.fileModel.SupplierCoaFileList.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i1++
            });
          }
          if(rowData.FileNames[2].names){
            let i2=0;
            rowData.FileNames[2].names.forEach((element) => {
              let nameK=rowData.FileNames[2].namesKey[i2]
              this.fileModel.SupplierSdsFileList.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i2++
            });
          }
          if(rowData.FileNames[3].names){
            let i3=0;
            rowData.FileNames[3].names.forEach((element) => {
              let nameK=rowData.FileNames[3].namesKey[i3]
              this.fileModel.OthersAttachmentsFileList.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i3++
            });
          }
          if(rowData.FileNames[4].names){
            let i4=0;
            rowData.FileNames[4].names.forEach((element) => {
              let nameK=rowData.FileNames[4].namesKey[i4]
              this.fileModel.CustomRowFileList1.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i4++
            });
          }
          if(rowData.FileNames[5].names){
            let i5=0;
            rowData.FileNames[5].names.forEach((element) => {
              let nameK=rowData.FileNames[5].namesKey[i5]
              this.fileModel.CustomRowFileList2.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i5++
            });
          }
          if(rowData.FileNames[6].names){
            let i6=0;
            rowData.FileNames[6].names.forEach((element) => {
              let nameK=rowData.FileNames[6].namesKey[i6]
              this.fileModel.CustomRowFileList3.push({
                name: element,
                url: this.$api.getFileUrl() + "/" + nameK,
                nameKey:nameK,
              });
              i6++
            });
          }
        }
      });
    },
  },
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.getDetailById(params);
    }
    // let localData = localStorage.getItem("tpsDataKey");
    // let data;
    // if (localData != "undefined") {
    //   data = JSON.parse(localStorage.getItem("tpsDataKey"));
    // }

    // if (data) {
    //   Object.keys(data).forEach((key) => {
    //     this.form[key] = data[key];
    //   });
    //   console.log("======文件=======");
    //   console.log(this.$api.getFileUrl());
    //   console.log(data);
    //   console.log(data.FileNames);
    //   if(data.FileNames[0].names){
    //     let i0=0;
    //     data.FileNames[0].names.forEach((element) => {
    //       let nameK=data.FileNames[0].namesKey[i0]
    //       this.fileModel.SupplierSpecFileList.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i0++
    //     });
    //   }
    //   if(data.FileNames[1].names){
    //     let i1=0;
    //     data.FileNames[1].names.forEach((element) => {
    //       let nameK=data.FileNames[1].namesKey[i1]
    //       this.fileModel.SupplierCoaFileList.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i1++
    //     });
    //   }
    //   if(data.FileNames[2].names){
    //     let i2=0;
    //     data.FileNames[2].names.forEach((element) => {
    //       let nameK=data.FileNames[2].namesKey[i2]
    //       this.fileModel.SupplierSdsFileList.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i2++
    //     });
    //   }
    //   if(data.FileNames[3].names){
    //     let i3=0;
    //     data.FileNames[3].names.forEach((element) => {
    //       let nameK=data.FileNames[3].namesKey[i3]
    //       this.fileModel.OthersAttachmentsFileList.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i3++
    //     });
    //   }
    //   if(data.FileNames[4].names){
    //     let i4=0;
    //     data.FileNames[4].names.forEach((element) => {
    //       let nameK=data.FileNames[4].namesKey[i4]
    //       this.fileModel.CustomRowFileList1.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i4++
    //     });
    //   }
    //   if(data.FileNames[5].names){
    //     let i5=0;
    //     data.FileNames[5].names.forEach((element) => {
    //       let nameK=data.FileNames[5].namesKey[i5]
    //       this.fileModel.CustomRowFileList2.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i5++
    //     });
    //   }
    //   if(data.FileNames[6].names){
    //     let i6=0;
    //     data.FileNames[6].names.forEach((element) => {
    //       let nameK=data.FileNames[6].namesKey[i6]
    //       this.fileModel.CustomRowFileList3.push({
    //         name: element,
    //         url: this.$api.getFileUrl() + "/" + nameK,
    //         nameKey:nameK,
    //       });
    //       i6++
    //     });
    //   }
    // }
  },
};
</script>
<style scoped>
.page {
  background: white;
  overflow: scroll;
  height: 90vh;
}
.row-title {
  float: left;
  margin-top: 10px;
}
.custom-row {
  margin-right: 10px;
  float: left;
  width: 46%;
}
.upload-demo {
  border: 1px solid #efefef;
}
.upload-demo .el-upload {
  float: left;
}
.save-bottom {
  padding-bottom: 40px;
}
</style>